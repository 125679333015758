/* eslint-disable react/display-name */
/* eslint-disable no-prototype-builtins */
import React, {useCallback, useEffect, useState} from 'react';
import {Box,
  Button,
  Grid,
  IconButton,
  Paper,
  withStyles,
  Modal,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {exportParticipant, getParticipants} from '../../../redux/eventManagement/eventManagement.actions';
import {EVENT_MANAGEMENT_STATE} from '../../../redux/eventManagement/eventManagement.types';
import {Datatable} from '../../../components';
import {MButton, MInput, MSelect} from '../../../components/form';
import {Refresh as ResetIcon, Search as SearchIcon, SaveAlt} from '@material-ui/icons';
import styles from './styles';
import {debounce as _debounce} from 'lodash';
import moment from 'moment';
import {convDate} from '../../../helpers';
import {ThemeProvider} from '@mui/material/styles';
import {createTheme, Snackbar} from '@mui/material';
import {useHistory} from 'react-router-dom';
import {updateExportNotification} from '../../../redux/exportActivity/exportActivity.actions';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/Download';
import DownloadIcon from '@mui/icons-material/Download'; // Material-UI download icon

const initialFilter = {
  pagination: true,
  page: 1,
  limit: 10,
  keyword: null,
  start_date: null,
  end_date: null,
};

const stylePopUp = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '832px',
  height: '510px',
  bgcolor: 'background.paper',
  borderRadius: '5px',
  display: 'flex',
  boxShadow: 24,
  p: 4,
  gap: '2px',
};

const columns = [
  {name: 'id', label: 'id', display: false},
  {name: 'name', label: 'Name', display: true},
  {name: 'email', label: 'Email', display: true},
  {
    name: 'package',
    label: 'Package',
    display: false,
    customBodyRender: (value) => <span style={{textTransform: 'capitalize'}}>{!value ? 'free' : value}</span>,
  },
  {
    name: 'voucher_code',
    label: 'Voucher',
    display: false,
    customBodyRender: (value) => value || '-',
  },
  {name: 'phone', label: 'Phone', display: true},
  {
    name: 'gender',
    label: 'Gender',
    display: true,
    customBodyRender: (value) => <span style={{textTransform: 'capitalize'}}>{value || '-'}</span>,
  },
  {name: 'job_title', label: 'Job Title', display: false},
  {name: 'company', label: 'Company', display: false},
  {
    name: 'createdAt',
    label: 'Joined At',
    display: false,
    customBodyRender: (value) => {
      if (!value || !value?.length) return '-';

      return <Button
        variant="outlined"
        size="small"
        style={{whiteSpace: 'nowrap'}}
        >
          {convDate(value, 'DD-MM-YYYY HH:mm:ss')}
      </Button>;
    },
  },
  {
    name: 'history',
    label: 'Check-In',
    display: true,
    customBodyRender: (value) => {
      if (!value || !value?.length) return '-';

      const time = value?.find((item) => {
        return item?.method === 'checkin';
      })?.time;

      return <Button
        variant="outlined"
        size="small"
        style={{whiteSpace: 'nowrap'}}
        >
          {convDate(time, 'DD-MM-YYYY HH:mm:ss')}
      </Button>;
    },
  },
  {
    name: 'history',
    label: 'Check-Out',
    display: true,
    customBodyRender: (value) => {
      if (!value || !value?.length) return '-';

      const findLast = value?.findIndex((item) => item?.method === 'checkout');

      if (findLast === -1) return '-';

      return <Button
          variant="outlined"
          size="small"
          style={{whiteSpace: 'nowrap'}}
          >
            {convDate(value[findLast]?.time, 'DD-MM-YYYY HH:mm:ss')}
        </Button>;
    },
  },
];

function capitalizeFirstLetter(text) {
  if (!text) return '';
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase().replace(/_/g, ' ');
}

function EventParticipants({classes}) {
  const dispatch = useDispatch();
  const [filterState, setFilterState] = useState({...initialFilter});
  const [filterDateOptions, setFilterDateOptions] = useState([{id: 'all', name: 'All'}]);
  const [filterVoucherOptions, setFilterVoucherOptions] = useState([{id: 'all', name: 'All'}]);
  const [snackbar, setSnackbar] = useState(false);
  const {
    eventDetail,
    isLoading,
    participants: data,
    isLoadingExportParticipant,
    trigger,
    isSuccess,
    exportData,
  } = useSelector((state) => state.eventManagement);

  const [imageUrl, setImageUrl] = useState(''); // Menyimpan URL gambar yang dipilih
  const [openImage, setOpenImage] = useState(false);
  const [selectedDetail, setSelectedDetail]= useState();
  const [expanded, setExpanded] = useState(false);
  const [expandedFile, setExpandedFile] = useState(null);
  const [open, setOpen] = React.useState(false);
  const isImageUrl = (url) => {
    return /\.(jpg|jpeg|png|gif|bmp|svg)$/i.test(url); // Memeriksa apakah URL adalah gambar
  };

  const isFileUrl = (url) => {
    return /\.(pdf|doc|docx|ppt|pptx|txt|xls|xlsx|zip)$/i.test(url); // Memeriksa apakah URL adalah file
  };

  const handleOpen = async (param) => {
    const numberId = param.rowData[1];
    const selected = data?.participants.find((data) => data.id === numberId);

    if (selected && selected.custom_field && Array.isArray(selected.custom_field)) {
      selected.custom_field = selected.custom_field.map((field) => {
        if (field && field.hasOwnProperty('value')) {
          let value = field.value;
          try {
            value = JSON.parse(value).join(', ');
          } catch (e) {
          //  return value
          }

          selected[field.slug] = value;
        }
        return field;
      });
    }

    if (!selected.history || !selected.history.length) {
      selected.checkin = '-';
      selected.checkout = '-';
    } else {
      const checkIn = selected.history.find((item) => item.method === 'checkin')?.time;
      const checkOut = selected.history.findIndex((item) => item.method === 'checkout');

      if (checkOut === -1) return '-';
      selected.checkout = convDate(selected.history[checkOut]?.time, 'DD-MM-YYYY HH:mm:ss');
      selected.checkin = convDate(checkIn, 'DD-MM-YYYY HH:mm:ss');
    }

    setSelectedDetail(selected);
    setOpen(true);
  };


  const handleClose = () => {
    setSelectedDetail();
    setOpen(false);
};

  const history = useHistory();
  const fetchParticipants = useCallback((params = initialFilter, _filter = null) => {
    const newParams = {
      ...initialFilter,
      ...params,
      keyword: _filter?.keyword || params?.keyword,
      start_date: _filter?.start_date === 'all' ? null : _filter?.start_date || params?.start_date,
      end_date: _filter?.end_date === 'all' ? null : _filter?.end_date || params?.end_date,
      event_id: eventDetail?.id,
    };

    if (params?.sortBy) {
      delete newParams.sortBy;
      newParams.order = params.sortBy;
      newParams.sort = params.order?.toUpperCase();
    }

    dispatch(getParticipants(newParams));
  }, [dispatch, eventDetail]);

  const handleApplyFilter = useCallback(_debounce(fetchParticipants, 300), []);

  const handleResetFilter = () => {
    setFilterState({...initialFilter});
    fetchParticipants(initialFilter);
  };

  const handleFilterChange = (event) => {
    const {name, value} = event?.target || {};
    let newState = {};

    if (name === 'start_date') {
      newState = {
        ...filterState,
        [name]: value && value!=='all' ? value : null,
        end_date: value && value!=='all' ? value : null,
      };
    } else {
      newState = {
        ...filterState,
        [name]: value ? value : null,
      };
    }

    if (name === 'voucher_code') {
      newState = {
        ...newState,
       [name]: value === 'all' ? null : value,
      };
    }

    setFilterState({...newState});
    handleApplyFilter(newState);
  };

  const handleFilterDateOptions = useCallback((_detail) => {
    const dateStart = moment(_detail?.date_start);
    const dateEnd = moment(_detail?.date_end);

    if (dateStart.startOf('day') === dateEnd.startOf('day')) {
      setFilterDateOptions((prevState) => ([
        ...prevState,
        {
          id: dateStart.format('DD MMMM YYYY'),
        }]
      ));
    } else {
      const diff = dateEnd.startOf('day').diff(dateStart.startOf('day'), 'days');
      const loopStart = dateStart.clone();
      const newFilterDateOptions = [];

      for (let i = 0; i <= diff; i++) {
        newFilterDateOptions.push({
          id: loopStart.format('YYYY-MM-DD'),
          name: loopStart.format('D MMMM YYYY'),
        });

        loopStart.add(1, 'days');
      }

      setFilterDateOptions((prevState) => ([...prevState, ...newFilterDateOptions]));
    }
  }, []);

  const handleFilterVoucherOptions = useCallback((_detail) => {
    const options = _detail?.voucher?.map((item) => ({id: item?.code, name: item?.code})) || [];
    setFilterVoucherOptions((prevState) => ([...prevState, ...options]));
  }, []);

  useEffect(() => {
    fetchParticipants();
    handleFilterDateOptions(eventDetail);
    handleFilterVoucherOptions(eventDetail);
  }, [eventDetail, fetchParticipants, handleFilterDateOptions, handleFilterVoucherOptions]);


  useEffect(()=>{
    if (trigger === EVENT_MANAGEMENT_STATE.EXPORT_PARTICIPANTS_SUCCESS && isSuccess) {
      setSnackbar(true);
      dispatch(updateExportNotification({
        fileName: eventDetail.title,
        ...exportData,
      }));
    }
  }, [trigger, isSuccess]);

  const handleExport = useCallback(() => {
    const params = {};
    if (filterState?.voucher_code) {
      params.voucher_code = filterState?.voucher_code;
    }
    if (filterState?.keyword) {
      params.keyword = filterState?.keyword;
    }

    if (filterState?.start_date) {
      params.start_date = filterState?.start_date;
    }
    if (filterState?.end_date) {
      params.end_date = filterState?.end_date;
    }
    params.event_id=eventDetail?.id;
    dispatch(exportParticipant(params));
  }, [dispatch, filterState]);

  const snackbarTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    components: {
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            color: '#000000 !important',
            backgroundColor: '#FFFFFF !important',
          },
        },
      },
    },
  });

  const handleCloseSnackbar = () => {
    setSnackbar(false);
  };

  const renderSnackbar = () => {
    const action = (
      <Button color="primary" size="small" onClick={()=>history.push('/export-activity')}>
        View Activity
      </Button>
    );
    return (
      <ThemeProvider theme={snackbarTheme()}>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={snackbar}
          onClose={handleCloseSnackbar}
          message="Your file is in the queue, click here to see the progress"
          action={action}
        />
      </ThemeProvider>
    );
  };


  const handleMapKey = (key) => {
    switch (key) {
      case 'checkin':
        return 'Check-In';
      case 'checkout':
        return 'Check-Out';
      case 'registered_at':
        return 'Joined At';
      case 'voucher_code':
        return 'Voucher';
      default:
        return capitalizeFirstLetter(key);
    }
  };

  const handleClickImage = (url) => {
    setImageUrl(url);
    setOpenImage(true);
  };

  const handleCloseModalImage = () => {
    setOpenImage(false);
    setImageUrl('');
  };

  const imageUrlWithProxy = `https://cors-anywhere.herokuapp.com/${imageUrl}`;

  const handleDownloadImage = async () => {
    try {
      const response = await fetch(imageUrlWithProxy);
      if (!response.ok) throw new Error('Failed to fetch image');

      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = imageUrl.split('/').pop();

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  const handleDownloadFile = (fileUrl, fileName) => {
    try {
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading File:', error);
    }
  };


  const mappingData = (data) => {
    const filteredData = Object.entries(data).filter(
      ([key]) =>
        key !== 'history' &&
        key !== 'custom_field' &&
        key !== 'id' &&
        key !== 'event_id' &&
        key !== 'user_id' &&
        key !== 'createdAt' &&
        key !== 'updatedAt' &&
        key !== 'referral_id' &&
        key !== 'company' &&
        key !== 'job_title' &&
        key !== 'invoice',
    );

    const imageAndFileData = filteredData.filter(([key, value]) => isImageUrl(value) || isFileUrl(value));
    const otherData = filteredData.filter(([key, value]) => !isImageUrl(value) && !isFileUrl(value));

    const combinedData = [...otherData, ...imageAndFileData];

    const chunkedData = [];
    for (let i = 0; i < combinedData.length; i += 7) {
      chunkedData.push(combinedData.slice(i, i + 7));
    }

    const columnWidth = chunkedData.length === 1 ? 12 : chunkedData.length === 2 ? 6 : 4;

    return (
      <Grid container spacing={2} style={{width: '98%'}}>
        {chunkedData.map((chunk, columnIndex) => (
          <Grid item xs={12} sm={columnWidth} key={columnIndex}>
            {chunk.map(([key, value], rowIndex) => (
              <div key={rowIndex} className="data-item" style={{margin: '0.5rem 0'}}>
                <Typography
                  variant="body1"
                  style={{
                    fontFamily: 'Poppins !important',
                    fontSize: '14px',
                    fontWeight: 400,
                    color: '#333333',
                  }}
                >
                  {handleMapKey(key)}
                </Typography>
                {isImageUrl(value) ? (
                  <Card
                    style={{display: 'inline-block', maxWidth: '100px', cursor: 'pointer'}}
                    onClick={() => handleClickImage(value)}
                  >
                    <CardMedia
                      component="img"
                      height="100"
                      image={value}
                      alt="Image preview"
                    />
                  </Card>
                ) : isFileUrl(value) ? (
                  <Card
                    style={{
                      display: 'inline-block',
                      width: '200px',
                      marginTop: '0.5rem',
                      overflowWrap: 'break-word'}}>
                    <CardContent style={{display: 'flex', alignItems: 'center', paddingBottom: '16px'}}>
                      <AttachmentIcon style={{marginRight: '10px'}} />
                      <div>
                        <Typography
                          variant="body2"
                          style={{
                            fontSize: '12px',
                            maxWidth: '98%',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: expandedFile === rowIndex ? 'none' : 2,
                            overflow: 'hidden',
                            wordBreak: 'break-word',
                          }}
                        >
                          {value.split('/').pop()}
                        </Typography>
                        {value && value.split('/').pop().length > 40 && (
                          <p
                            onClick={() => setExpandedFile(expandedFile === rowIndex ? null : rowIndex)}
                            style={{fontSize: '12px', color: '#00AFF0', cursor: 'pointer', marginBottom: '0px'}}
                          >
                            {expandedFile === rowIndex ? 'See Less' : 'See More'}
                          </p>
                        )}
                      </div>
                      <IconButton
                        onClick={() => handleDownloadFile(value, value.split('/').pop())}
                        style={{padding: '5px', color: '#00AFF0'}}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </CardContent>
                  </Card>
                ) : (
                  <div>
                    <Typography
                      style={{
                        fontFamily: 'Product Sans !important',
                        fontSize: '12px',
                        fontWeight: 400,
                        color: '#656464',
                        overflowWrap: 'break-word',
                        maxWidth: '90%',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: expanded ? 'none' : 3,
                        overflow: 'hidden',
                      }}
                      variant="body2"
                    >
                      {value ? value : '-'}
                    </Typography>
                    {value && value.length > 100 && (
                      <p
                        onClick={() => setExpanded(!expanded)}
                        style={{fontSize: '12px', color: '#00AFF0', cursor: 'pointer'}}
                      >
                        {expanded ? 'See Less' : 'See More'}
                      </p>
                    )}
                  </div>
                )}
              </div>
            ))}
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <>
    <Box>
      <Paper className={classes.paper}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={4} lg={4}>
            <MInput
              autoFocus
              fullWidth
              variant="standard"
              name="keyword"
              label="Search"
              placeholder="Search keyword"
              onChange={handleFilterChange}
              value={filterState?.keyword || ''}
              InputProps={{
                endAdornment: <IconButton position="end" className={classes.searchIcon}>
                  <SearchIcon />
                </IconButton>,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={3} lg={2}>
            <MSelect
              shrink={true}
              fullWidth
              variant="standard"
              name="start_date"
              label="Check-In Date"
              keyPair={['id', 'name']}
              options={filterDateOptions}
              onChange={handleFilterChange}
              value={filterState?.start_date || filterDateOptions[0].id}
            />
          </Grid>

          <Grid item xs={12} sm={3} lg={2}>
            <MSelect
              shrink={true}
              fullWidth
              variant="standard"
              name="voucher_code"
              label="Voucher"
              keyPair={['id', 'name']}
              options={filterVoucherOptions}
              onChange={handleFilterChange}
              value={filterState?.voucher_code || filterVoucherOptions[0].id}
            />
          </Grid>

          <Grid item xs={12} sm={2} lg={4}>
            <Box sx={{display: 'flex', gap: '16px'}}>
            <MButton
              label="Reset"
              color="primary"
              icon={<ResetIcon />}
              onClick={handleResetFilter}
            />
            <MButton
              label="Export"
              color="primary"
              icon={<SaveAlt/>}
              loading={isLoadingExportParticipant}
              onClick={handleExport}
              disabled={isLoadingExportParticipant ? true : false}
            />
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Datatable
        title="Participants"
        dataSource={data?.participants}
        total={data?.paginator?.itemCount}
        page={data?.paginator?.page || 1}
        columns={columns}
        handleDetail={(data) => handleOpen(data)}
        creatable={false}
        loading={isLoading}
        handleReload={(params) => fetchParticipants(params, filterState)}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={stylePopUp}>
          <Box style={{width: '100%', height: '100%'}}>
            <Box style={{width: '100%', height: '90%', overflow: 'auto', display: 'grid'}}>
              <Typography
              id="modal-modal-title"
              style={{
                fontFamily: 'Poppins !important',
                fontWeight: 600,
                color: '#595959',
                height: 'fit-content',
                 marginBottom: '10px',
                 }}
                variant="h6" component="h2">
                Detail Participant
              </Typography>
              <Box style={{overflow: 'auto', width: '100%'}}>
                {selectedDetail && mappingData(selectedDetail)}
              </Box>
            </Box>
            <Box style={{
              display: 'flex',
              bottom: '10px',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              marginTop: '10px'}}>
              <Button style={{
                borderRadius: 4,
                padding: '4px 16px',
                backgroundColor: '#00AFF0',
                color: '#FFFF'}}
                onClick={handleClose}>
                  Close
                </Button>
            </Box>
          </Box>

        </Box>
      </Modal>
      <Dialog open={openImage} onClose={handleCloseModalImage}>
        <DialogTitle>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseModalImage}
            aria-label="close"
            style={{position: 'absolute', right: 8, top: 8}}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <img
            src={imageUrl}
            alt="Large Preview"
            style={{width: '100%', height: 'auto', objectFit: 'contain'}}
          />
        </DialogContent>
        <DialogActions>
          <IconButton color="primary" onClick={handleDownloadImage}>
            <FileDownloadIcon />
          </IconButton>
          <Typography variant="body2" style={{marginRight: '10px'}}>
            Download
          </Typography>
        </DialogActions>
      </Dialog>
    </Box>
    {renderSnackbar()}
    </>
  );
}

export default withStyles(styles)(EventParticipants);
