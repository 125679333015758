/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import React, {Component, Fragment} from 'react';
import {
  Paper,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  CardMedia,
  Link,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Search,
  Refresh,
  CheckCircle,
  Cancel,
  Send,
  SaveAlt,
} from '@material-ui/icons';
import {
  MInput,
  MKeyBoardDatepicker,
  MButton,
  ModalTitle,
  MSelect,
  MDatepicker,
} from '../../components/form';
import ImageCropper from '../../components/cropper';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';
import {Datatable} from '../../components';
import {toast, isObject, convDate, convText} from '../../helpers';
import {getPermitHistory, putPermitStatus, exportExcel} from './../../redux/permit/permit.actions';
import {PERMIT_STATE as STATE} from '../../redux/permit/permit.type';
import {Snackbar, createTheme as themeTemplate} from '@mui/material';
import {updateExportNotification} from '../../redux/exportActivity/exportActivity.actions';
import {checkStatus as checkStatusUser} from '../../redux/userManagement/userManagement.actions';
import {checkStatus} from '../../redux/organization/organization.actions'; import _ from 'lodash';
import moment from 'moment';
import Swal from 'sweetalert2';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Checkbox, FormControlLabel} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import TimelineContent from '@mui/lab/TimelineContent';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, {timelineItemClasses} from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineDot from '@mui/lab/TimelineDot';
import Divider from '@mui/material/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


class Permit extends Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      limit: 10,
      query: null,
      startDate: null,
      endDate: null,
      sortBy: null,
      order: 'desc',
      method: 'all',
      showDetail: false,
      showReject: false,
      showApprove: false,
      selectedStatus: 'waiting',
      detailData: {},
      resetDatatable: null,
      resetPage: null,
      status: 'waiting',
      searchBy: null,
      formData: {},
      errorMessage: null,
      errorFilterDate: false,
      checkboxFilter: false,
      timelineAccordion: false,
      snackbar: false,
      snackbarVertical: 'top',
      snackbarHorizontal: 'right',
    };
  }

  checkboxTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
  })

  tooltipTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    components: {
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: '18px !important',
            marginLeft: '6px !important',
            marginTop: '-1px !important',
          },
        },
      },
    },
  });

  timelineTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
      secondary: {
        main: '#FFC226',
      },
      warning: {
        main: '#F00B38',
      },
      success: {
        main: '#008000',
      },
    },
    components: {
      MuiTimelineItem: {
        styleOverrides: {
          root: {
            minHeight: '46px !important',
          },
        },
      },
      MuiTimelineContent: {
        styleOverrides: {
          root: {
            padding: '6px 4px 6px 12px !important',
          },
        },
      },
      MuiTimeline: {
        styleOverrides: {
          root: {
            padding: '6px 16px 0px 16px !important',
          },
        },
      },
    },
  });

  snackbarTheme = () => themeTemplate({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    components: {
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            color: '#000000 !important',
            backgroundColor: '#FFFFFF !important',
          },
        },
      },
    },
  });

   accordionTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    components: {
      MuiAccordionSummary: {
        styleOverrides: {
          content: {
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: '0px !important',
            margin: '13px 0px 8px 0px !important',
          },
          // expanded: {
          //   // display: 'flex',
          //   justifyContent: 'space-between',
          //   minHeight: '0px !important',
          //   // margin: '8px 0px 0px 0px !important',
          // },
          root: {
            'minHeight': '0px !important',
            '&.Mui-expanded': {
              'justifyContent': 'space-between',
              'minHeight': '0px !important',
            },
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            display: '',
            padding: '0px !important',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: '8px 4px 8px 12px !important',
            marginTop: '6px !important',
          },
        },
      },
    },
  });

  UNSAFE_componentWillMount() {
    const {
      page,
      limit,
      startDate,
      endDate,
      query,
      sortBy,
      order,
      method,
      status,
      searchBy,
    } = this.state;

    const url_string = window.location.href;
    const url = new URL(url_string);
    const date = url.searchParams.get('date');
    const submission_date = url.searchParams.get('type');
    const statusFilter = url.searchParams.get('status');


    this.props.checkStatus();
    this.props.checkStatusUser();

    const name = url.searchParams.get('query');

    if (name) {
      this.setState({query: name}, () => {
        this.handleDebounceSearch(name);
      });
    } else {
      this.props.getPermitHistory({
        ...(page && {page}),
        ...(limit && {limit}),
        ...(query && {query}),
        ...(date && {start_date: date ? convDate(moment(), 'YYYY-MM-DD') : null}),
        ...(date && {end_date: date ? convDate(moment(), 'YYYY-MM-DD') : null}),
        ...(sortBy && {sort_by: sortBy}),
        ...(order && {order}),
        status: statusFilter ? statusFilter : 'waiting',
        // ...(submission_date && submission_date !== 'all' && {searchBy: submission_date}),
      });
    }
  }

  componentDidMount() {

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {isError, message, trigger, isSuccessPut, isErrorPut, status, exportData, isSuccess} = nextProps;

    if (trigger === STATE.EXPORT_SUCCESS && isSuccess) {
      this.setState({snackbar: true});
      this.props.updateExportNotification({
        fileName: 'Permit',
        ...exportData,
      });
    }

    if (isError && message && !isObject(message)) toast('error', message);
    if (isSuccessPut) {
      if (status === 'approved') {
        toast('success', 'Successfuly Approved Permit');
      } else if (status === 'rejected') {
        toast('success', 'Successfuly Rejected Permit');
      }
      this.handleResetForm(true);
      this.fetchPermit();
      this.props.checkStatus();
    } else if (isErrorPut) {
      if (status === 'approved') {
        toast('error', 'Failed Approved Permit');
      } else if (status === 'rejected') {
        toast('error', 'Failed Rejected Permit');
      }
      this.handleResetForm(true);
      this.fetchPermit();
      this.props.checkStatus();
    }
  }

  handleResetForm = (closeModal) => {
    this.setState({
      errorMessage: null,
      formData: {},
      detailData: {},
    });
    if (closeModal) this.setState({showDetail: false, showReject: false, showApprove: false});
  };

  handleSearch = (event) => {
    const {value} = event.target;
    this.setState({query: value}, () => {
      this.handleDebounceSearch(value);
    });
  };

  handleFilterCheckbox = (event) => {
    const {checked} = event.target;

    if (checked) {
      this.setState({
        filterCheckbox: checked,
        startDate: convDate(moment()),
        endDate: convDate(moment()),
      });

      const state = {
        page: 1,
        limit: 10,
        query: '',
        sortBy: null,
        order: 'desc',
        method: 'all',
        status: 'all',
        searchBy: 'submission_date',
        errorFilterDate: false,
      };
      this.setState(state, () => this.fetchPermit());
      this.toggleResetDatatable(true);
      this.toggleResetPage();
    } else {
      this.setState({
        filterCheckbox: checked,
      });

      const state = {
        page: 1,
        limit: 10,
        query: '',
        startDate: null,
        endDate: null,
        sortBy: null,
        order: 'desc',
        method: 'all',
        status: 'waiting',
        errorFilterDate: false,
        searchBy: null,
      };
      this.setState(state, () => this.fetchPermit());
      this.toggleResetDatatable(true);
      this.toggleResetPage();
    }
  }

  toggleResetPage = () => {
    this.setState({resetPage: true, page: 1, limit: 10}, () =>
      this.setState({resetPage: null}),
    );
  };

  handleReset = () => {
    const state = {
      page: 1,
      limit: 10,
      query: '',
      startDate: convDate(moment()),
      endDate: convDate(moment()),
      sortBy: null,
      order: 'desc',
      method: 'all',
      status: 'all',
      searchBy: 'submission_date',
      errorFilterDate: false,
    };
    this.setState(state, () => this.fetchPermit());
    this.toggleResetDatatable(true);
    this.toggleResetPage();
  };

  toggleResetDatatable = (status) => {
    this.setState({resetDatatable: status}, () =>
      this.setState({resetDatatable: null}),
    );
  };

  handleDebounceSearch = _.debounce((query) => {
    this.toggleResetPage();
    this.setState({page: null}, () => this.fetchPermit());
  }, 500);

  handleFilterDateChange = (params) => {
    const {value, name} = params;
    this.setState(
        {[name]: value, page: null},
    );
  };

  handleFilterChange = (event) => {
    const {value, name} = event.target;
    this.setState({[name]: value, page: null});
  };

  renderTableTitle = () => {
    const {classes} = this.props;
    return (
      <div className={classes.MUIDataTableToolbar}>
        <div className="d-flex">
          <Typography className={classes.tableTitle}>Permit List</Typography>
          <ThemeProvider theme={this.tooltipTheme()}>
            <Tooltip title="Click Filter to see all data from permit list">
              <IconButton className={classes.tableTooltip}>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </ThemeProvider>
          {/* <span className="">Remaining Quota: {remaining_quota}</span>   */}
        </div>
      </div>
    );
  };

  handleButtonFilter = () => {
    const {
      page,
      limit,
      startDate,
      endDate,
      query,
      sortBy,
      order,
      method,
      status,
      searchBy,
    } = this.state;

    if (startDate === null || endDate === null) {
      return this.setState({
        errorFilterDate: true,
      });
    }

    this.props.getPermitHistory({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(query && {query}),
      ...(startDate && {start_date: convDate(startDate)}),
      ...(endDate && {end_date: convDate(endDate)}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
      ...(status && status !== 'all' && {status}),
      ...(searchBy && searchBy !== 'all' && {searchBy}),
    });
  }

  handleExport = () => {
    const {page, limit, startDate, endDate, query, sortBy, order, method, completed, status, searchBy} = this.state;
    this.props.exportExcel({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(query && {query}),
      ...(startDate && {start_date: convDate(startDate)}),
      ...(endDate && {end_date: convDate(endDate)}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
      ...(method && method !== 'all' && {method}),
      ...(completed !== null && completed !== undefined && {completed}),
      ...(status && status !== 'all' && {status}),
      ...(searchBy && {searchBy}),
    });
  }

  handleCloseSnackbar = () => {
    this.setState({snackbar: false});
  };

  renderSnackbar = () => {
    const {snackbar, snackbarVertical, snackbarHorizontal} = this.state;
    const action = (
      <Button color="primary" size="small" onClick={() => this.props.history.push('/export-activity')}>
        View Activity
      </Button>
    );
    return (
      <ThemeProvider theme={this.snackbarTheme()}>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={snackbar}
          onClose={this.handleCloseSnackbar}
          message="Your file is in the queue, click here to see the progress"
          key={snackbarVertical + snackbarHorizontal}
          action={action}
        />
      </ThemeProvider>
    );
  }

  renderFilter = () => {
    const {classes, message, exportLoading, permitData} = this.props;
    const {query, startDate, endDate, status, searchBy} = this.state;
    const renderMethodOptions = () => {
      const options = [
        {id: 'all', name: 'All'},
        // {id: 'waiting', name: 'Waiting'},
        {id: 'approved', name: 'Approved'},
        {id: 'rejected', name: 'Rejected'},
      ];
      return options;
    };

    const renderSearchByOptions = () => {
      const options = [
        {id: 'submission_date', name: 'Permit Date'},
        {id: 'confirmed_date', name: 'Confirmed Date'},
      ];
      return options;
    };

    return (
      <Paper variant='outlined' className={classes.paper}>
        {this.state.filterCheckbox ? (
          <>
            <MInput
              autoFocus
              className={`${classes.filter} ${classes.searchInput}`}
              fullWidth={false}
              variant="standard"
              name="query"
              label="Search"
              placeholder="Search keyword"
              value={query}
              onChange={this.handleSearch}
              InputProps={{
                endAdornment: (
                  <IconButton position="end">
                    <Search className={classes.searchIcon} />
                  </IconButton>
                ),
              }}
            />
            <ThemeProvider theme={this.checkboxTheme()}>
              <FormControlLabel
                style={{display: 'inline-flex', verticalAlign: 'bottom'}}
                control={
                  <Checkbox
                    color='primary'
                    checked={this.state.filterCheckbox}
                    onChange={(e) => this.handleFilterCheckbox(e)}
                  />
                }
                label="Filter"
              />
            </ThemeProvider>
            <MSelect
              shrink={true}
              fullWidth={false}
              classNameFC={`${classes.formControlSearchBy} ${classes.filter}`}
              variant="standard"
              name="searchBy"
              label="Search By"
              keyPair={['id', 'name']}
              options={renderSearchByOptions()}
              value={searchBy}
              onChange={this.handleFilterChange}
            />
            <MSelect
              shrink={true}
              fullWidth={false}
              classNameFC={`${classes.formControlStatus} ${classes.filter}`}
              variant="standard"
              name="status"
              label="Status"
              keyPair={['id', 'name']}
              options={renderMethodOptions()}
              value={status}
              onChange={this.handleFilterChange}
            />
            <MKeyBoardDatepicker
              className={classes.filterDatePicker}
              name="startDate"
              label="Start Date"
              value={startDate}
              onDateChange={this.handleFilterDateChange}
              placeholder="dd-mm-yyyy"
              maxDate={endDate ? new Date(endDate) : undefined}
              error={
                this.state.startDate === null && this.state.endDate !== null ? 'Start Date is required' :
                  startDate === null && this.state.errorFilterDate ? 'Start Date is required' :
                    undefined
              }
            />
            <MKeyBoardDatepicker
              className={classes.filterDatePicker}
              name="endDate"
              label="End Date"
              value={endDate}
              onDateChange={this.handleFilterDateChange}
              placeholder="dd-mm-yyyy"
              minDate={new Date(startDate)}
              error={
                this.state.startDate !== null && this.state.endDate === null ? 'End Date is required' :
                  endDate === null && this.state.errorFilterDate ? 'End Date is required' :
                    undefined
              }
            />
            <div className={classes.btnFilterContainer}>
              <MButton
                className={classes.filterBtn}
                label="Apply"
                color="primary"
                onClick={this.handleButtonFilter}
              />
              <MButton
                className={classes.filterBtn}
                label="Reset"
                color="primary"
                icon={<Refresh />}
                onClick={this.handleReset}
              />
              <MButton
                className={`${classes.btnExport} ${classes.filterBtnEnd}`}
                label="Export"
                icon={<SaveAlt />}
                onClick={this.handleExport}
                loading={exportLoading}
                disabled={permitData?.length < 1 ? true : false}
              />
            </div>
          </>
        ) : (
          <Grid container justifyContent='space-between'>
            <Grid item>
              <MInput
                autoFocus
                className={`${classes.filter} ${classes.searchInput}`}
                fullWidth={false}
                variant="standard"
                name="query"
                label="Search"
                placeholder="Search keyword"
                value={query}
                onChange={this.handleSearch}
                InputProps={{
                  endAdornment: (
                    <IconButton position="end">
                      <Search className={classes.searchIcon} />
                    </IconButton>
                  ),
                }}
              />
              <ThemeProvider theme={this.checkboxTheme()}>
                <FormControlLabel
                  style={{display: 'inline-flex', verticalAlign: 'bottom'}}
                  control={
                    <Checkbox
                      color='primary'
                      checked={this.state.filterCheckbox}
                      onChange={(e) => this.handleFilterCheckbox(e)}
                    />
                  }
                  label="Filter"
                />
              </ThemeProvider>
            </Grid>
            <Grid item>
              <div className={classes.btnFilterContainer}>
                <MButton
                  className={`${classes.btnExport} ${classes.filterBtnEnd}`}
                  label="Export"
                  icon={<SaveAlt />}
                  onClick={this.handleExport}
                  disabled={permitData?.length < 1 ? true : false}
                  loading={exportLoading}
                />
                <br />
              </div>
            </Grid>
          </Grid>
        )}
      </Paper>
    );
  }

  fetchPermit = () => {
    const {
      page,
      limit,
      startDate,
      endDate,
      query,
      sortBy,
      order,
      method,
      status,
      searchBy,
    } = this.state;
    this.props.getPermitHistory({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(query && {query}),
      ...(startDate && {start_date: convDate(startDate)}),
      ...(endDate && {end_date: convDate(endDate)}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
      ...(status && status !== 'all' && {status}),
      ...(searchBy && searchBy !== 'all' && {searchBy}),
    });
  };

  handleReload = (params) => {
    this.setState(params, () => this.fetchPermit());
  };

  renderActions = (params) => {
    return (
      <div style={{whiteSpace: 'nowrap'}}>
        {params[6] && params[6] === 'waiting' ? (
          <>
            <div style={{display: 'inline', marginRight: '5px'}}>
              <Tooltip title="Approved">
                <Button
                  variant="contained"
                  // color="primary"
                  style={{
                    'backgroundColor': '#008000',
                    'boxShadow': 'none',
                    'color': '#fff',
                    '&:hover': {
                      boxShadow: 'none',
                      backgroundColor: '#007000',
                    },
                    'minWidth': '10px',
                    'paddingRight': '0px',
                  }}
                  size="small"
                  onClick={() => this.handleChangeStatus(params, 'approved')}
                  startIcon={<CheckCircle />}
                  >
                </Button>
              </Tooltip>
            </div>
            <div style={{display: 'inline', marginRight: '5px'}}>
              <Tooltip title="Rejected">
                <Button
                  variant="contained"
                  // color="primary"
                  size="small"
                  style={{
                    'backgroundColor': '#E5AF5A',
                    'boxShadow': 'none',
                    'color': '#fff',
                    '&:hover': {
                      boxShadow: 'none',
                      backgroundColor: '#D09F53',
                    },
                    'minWidth': '10px',
                    'paddingRight': '0px',
                  }}
                  onClick={() => this.handleChangeStatus(params, 'rejected')}
                  startIcon={<Cancel />}
                  >
                </Button>
              </Tooltip>
            </div>
          </>
        ) : null}
      </div>
    );
  };

  handleChangeStatus = (data, status) => {
    const {permitData} = this.props;
    let detail;
    permitData.filter((val, i) => {
      if (val.id === data[10]) {
        // val.status = status;
        detail = val;
      }
    });
    this.setState({
      selectedStatus: data[6],
      formData: detail,
      detailData: detail,
    }, () => {
      if (status === 'approved') {
        this.setState({
          showApprove: true,
          detailData: {
            id: detail.id,
          },
        });
      } else {
        this.setState({
          showReject: true,
          detailData: {
            id: detail.id,
          },
        });
      }
    });
  }

  toggleModal = (type, data) => {
    const {permitData} = this.props;
    if (type === 'detail') {
      const numberId = data.rowData[0]; // get detail attendance from datatable response, based on index
      const selected = permitData.filter((data) => data.no === numberId);
      const detailData = selected && selected.length ? selected[0] : null;
      this.setState({
        selectedStatus: detailData.status,
        formData: detailData,
        detailData: detailData,
        showDetail: true,
        errorMessage: null,
      });
    } else if (type === 'close') {
      this.setState({showDetail: false,
        detailData: {},
        formData: {},
        showReject: false,
        showApprove: false,
        errorMessage: null,
      });
    }
  };

  toggleTimelineAccordion = () => {
    this.setState({
      timelineAccordion: !this.state.timelineAccordion,
    });
  }


  renderModalDetail = () => {
    const {classes, isLoadingPut} = this.props;
    const {showDetail, detailData, formData, errorMessage, timelineAccordion} = this.state;
    const {id, approval_history, name, type, remarks, start_date, end_date, total_days, image, details, status, rejected_reason} = formData;
    const optionsType = [
      {id: 'sick', name: 'Sick'},
      {id: 'other', name: 'Other'},
    ];
    const optionsStatus = [
      {id: 'rejected', name: 'Rejected'},
      {id: 'approved', name: 'Approved'},
    ];

    const reversed = approval_history ? approval_history.reverse() : [];

    return (
      <Dialog
        open={showDetail}
        onClose={() => this.toggleModal('close')}
        maxWidth="sm"
        fullWidth={true}
        scroll="paper"
      >
        <ModalTitle
          className={classes.modalTitle}
          title="Permit Detail"
          onClick={() => this.toggleModal('close')}
        />
        <DialogContent className={classes.dialogContent}>
          <div style={{marginBottom: 24}}>
            <ThemeProvider theme={this.accordionTheme()}>
              <Accordion expanded={timelineAccordion} onChange={this.toggleTimelineAccordion} variant='outlined'>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className={classes.accordionTitle}>
                  Status
                  </Typography>
                  <Typography
                    className={status === 'request' ? classes.statusRequest :
                      status === 'on_going' ? classes.statusOngoing :
                        status === 'rejected' ? classes.statusRejected :
                          status === 'approved' ? classes.statusCompleted : null}
                  >
                    {status === 'request' ? 'Request' :
                      status === 'on_going' ? 'On Going' :
                        status === 'rejected' ? 'Rejected' :
                          status === 'approved' ? 'Approved' : null}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Divider style={{marginBottom: 8}} />
                  <ThemeProvider theme={this.timelineTheme()}>
                    <Timeline
                      sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                          flex: 0,
                          padding: 0,
                        },
                      }}
                    >
                      {reversed?.map((data, index) => {
                        return (
                          <TimelineItem key={index.toString()}>
                            <TimelineSeparator>
                              <TimelineDot color={index === reversed?.length - 1 && data.status === 'request' ? 'primary' :
                                index === reversed?.length - 1 && data.status === 'on_going' ? 'secondary' :
                                  index === reversed?.length - 1 && data.status === 'rejected' ? 'warning' :
                                    index === reversed?.length - 1 && data.status === 'approved' ? 'success' : 'grey'} />
                              {index === reversed?.length - 1 ? (null) : (<TimelineConnector />)}
                            </TimelineSeparator>
                            <TimelineContent>
                              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <div>
                                  <Typography
                                    className={data.status === 'request' ? classes.statusRequest :
                                      data.status === 'on_going' ? classes.statusOngoing :
                                        data.status === 'rejected' ? classes.statusRejected :
                                          data.status === 'approved' ? classes.statusCompleted : null}
                                  >
                                    {data.status === 'request' ? 'Request' :
                                      data.status === 'on_going' ? 'On Going' :
                                        data.status === 'rejected' ? 'Rejected' :
                                          data.status === 'approved' ? 'Approved' : null}
                                  </Typography>
                                  {data.status === 'approved' && (
                                    <Typography className={classes.timelineDate}>
                                      Approved by: {data.name}
                                    </Typography>
                                  )}
                                  <Typography
                                    className={classes.timelineDate}
                                  >
                                    {data.status === 'rejected' ? data.notes : null}
                                  </Typography>
                                </div>
                                <Typography className={classes.timelineDate}>{convDate(data.date, 'DD-MM-YYYY')}</Typography>
                              </div>
                            </TimelineContent>
                          </TimelineItem>
                        );
                      })}
                    </Timeline>
                  </ThemeProvider>
                </AccordionDetails>
              </Accordion>
            </ThemeProvider>
          </div>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs={3}>
              <Typography>Name </Typography>
            </Grid>
            <Grid item>
              <Typography> : </Typography>
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography>{name}</Typography>
            </Grid>
          </Grid>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs={3}>
              <Typography>Type </Typography>
            </Grid>
            <Grid item>
              <Typography> : </Typography>
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography>{convText(type)}</Typography>
            </Grid>
          </Grid>
          {this.renderDetailDatePermit(type, start_date)}
          {this.renderDetailAttachmentPermit(type, image)}
          {this.renderDetailSubTypePermit(type, remarks)}
          {this.renderDetailRangeDatePermit(type, start_date, end_date)}
          {this.renderDetailTotalDaysPermit(type, total_days)}
          <Grid>
            <MInput
              classNameFC={classes.formControl}
              multiline
              rows={2}
              rowsMax={3}
              name="details"
              label="Permit Statement"
              placeholder="Fill in leave statement"
              defaultValue={details}
              onBlur={this.handleInputChange}
              error={
                errorMessage && errorMessage.details ?
                  errorMessage.details :
                  undefined
              }
              disabled={true}
            />
          </Grid>
          <Grid>
            <MSelect
              fullWidth
              classNameFC={classes.formControl}
              name="status"
              label="Status *"
              placeholder="Confirm status"
              keyPair={['id', 'name']}
              options={optionsStatus}
              defaultValue={status}
              onChange={this.handleInputChange}
              error={
                errorMessage && errorMessage.status ?
                  errorMessage.status :
                  undefined
              }
            />
          </Grid>
          <Grid>
            <MInput
              classNameFC={classes.formControl}
              multiline
              rows={2}
              rowsMax={3}
              name="rejected_reason"
              label="Notes *"
              placeholder="Fill in notes"
              defaultValue={rejected_reason}
              onBlur={this.handleInputChange}
              error={
                errorMessage && errorMessage.rejected_reason ?
                  errorMessage.rejected_reason :
                  undefined
              }
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <MButton className={classes.cancelBtn} label="Cancel" onClick={() => this.toggleModal('close')} />
          <MButton
            className={classes.defaultBtn}
            label="Update"
            icon={<Send />}
            onClick={() => this.changePermitStatus(id)}
            loading={isLoadingPut}
          />
        </DialogActions>
      </Dialog>
    );
  }

  renderModalRejected = () => {
    const {classes, isLoadingPut} = this.props;
    const {showReject, detailData, errorMessage} = this.state;
    const rejected_reason =
      detailData && detailData.rejected_reason ?
        detailData.rejected_reason :
        '';
    const id = detailData && detailData.id ? detailData.id : null;
    return (
      <Dialog
        open={showReject}
        onClose={() => this.toggleModal('close')}
        maxWidth="sm"
        fullWidth={true}
        scroll="paper"
      >
        <ModalTitle
          className={classes.modalTitle}
          title="Permit Reject"
          onClick={() => this.toggleModal('close')}
        />
        <DialogContent className={classes.dialogContent}>
          <MInput
            classNameFC={classes.formControl}
            multiline
            rows={2}
            rowsMax={3}
            name="rejected_reason"
            label="Notes *"
            placeholder="Fill in notes"
            defaultValue={rejected_reason}
            onBlur={this.handleInputChange}
            error={
              errorMessage && errorMessage.rejected_reason ?
                errorMessage.rejected_reason :
                undefined
            }
          />
        </DialogContent>
        <DialogActions>
          <MButton className={classes.cancelBtn} label="Cancel" onClick={() => this.toggleModal('close')} />
          <MButton
            className={classes.defaultBtnReject}
            label="Reject"
            icon={<Send />}
            onClick={() => this.changePermitStatus(id)}
            loading={isLoadingPut}
          />
        </DialogActions>
      </Dialog>
    );
  };

  renderModalApproved = () => {
    const {classes, isLoadingPut} = this.props;
    const {showApprove, detailData, errorMessage} = this.state;
    const rejected_reason =
      detailData && detailData.rejected_reason ?
        detailData.rejected_reason :
        '';
    const id = detailData && detailData.id ? detailData.id : null;
    return (
      <Dialog
        open={showApprove}
        onClose={() => this.toggleModal('close')}
        maxWidth="sm"
        fullWidth={true}
        scroll="paper"
      >
        <ModalTitle
          className={classes.modalTitle}
          title="Permit Approve"
          onClick={() => this.toggleModal('close')}
        />
        <DialogContent className={classes.dialogContent}>
          <MInput
            classNameFC={classes.formControl}
            multiline
            rows={2}
            rowsMax={3}
            name="rejected_reason"
            label="Notes *"
            placeholder="Fill in notes"
            defaultValue={rejected_reason}
            onBlur={this.handleInputChange}
            error={
              errorMessage && errorMessage.rejected_reason ?
                errorMessage.rejected_reason :
                undefined
            }
          />
        </DialogContent>
        <DialogActions>
          <MButton className={classes.cancelBtn} label="Cancel" onClick={() => this.toggleModal('close')} />
          <MButton
            className={classes.defaultBtnReject}
            label="Approve"
            icon={<Send />}
            onClick={() => this.changePermitStatusApprove(id)}
            loading={isLoadingPut}
          />
        </DialogActions>
      </Dialog>
    );
  };

  changePermitStatus = (id) => {
    const {formData, showReject, showDetail, selectedStatus} = this.state;
    const {type, remarks, start_date, end_date, total_days, details, status, rejected_reason} = formData;
    const errors = {};
    const form = new FormData();
    if (showReject && !rejected_reason) {
      errors.rejected_reason = 'Notes is required';
      this.setState({errorMessage: errors});
    } else if (
      showDetail &&
      type==='sick' &&
      (
        !start_date ||
        !end_date ||
        (moment(start_date) > moment(end_date)) ||
        (moment(end_date) < moment(start_date)) ||
        (!total_days || parseInt(total_days) < 1) ||
        !details ||
        (!status || status==='waiting') ||
        (!rejected_reason)
      )
    ) {
      if (!start_date) errors.start_date = 'Start date is required';
      if (!end_date) errors.end_date = 'End date is required';
      if (moment(start_date) > moment(end_date)) errors.start_date = `Start date can't greater then end date`;
      if (moment(end_date) < moment(start_date)) errors.end_date = `End date can't lower then start date`;
      if (!total_days || parseInt(total_days) < 1) errors.total_days = `Total days minimal 1 day`;
      if (!details) errors.details = `Leave statement is required`;
      if (!status || status==='waiting') errors.status = `Status is required`;
      if (!rejected_reason) errors.rejected_reason = `Notes is required`;
      this.setState({errorMessage: errors});
    } else if (
      showDetail &&
      type==='other' &&
      (
        !remarks ||
        !details ||
        (!status || status==='waiting') ||
        (!rejected_reason)
      )
    ) {
      if (!remarks) errors.remarks = `Permit type is required`;
      if (!details) errors.details = `Leave statement is required`;
      if (!status || status==='waiting') errors.status = `Status is required`;
      if (!rejected_reason) errors.rejected_reason = `Notes is required`;
      this.setState({errorMessage: errors});
    } else if (
      showDetail &&
      (type !=='other' || type !=='sick') &&
      (
        (!status || status==='waiting') ||
        (!rejected_reason)
      )
    ) {
      if (!status || status==='waiting') errors.status = `Status is required`;
      if (!rejected_reason) errors.rejected_reason = `Notes is required`;
      this.setState({errorMessage: errors});
    } else {
      if (showDetail) {
        if (type === 'sick') {
          form.append('type', type);
          form.append('start_date', start_date);
          form.append('end_date', end_date);
          form.append('total_days', total_days);
          form.append('details', details);
          if (selectedStatus !==status) {
            form.append('status', status);
          }
          form.append('rejected_reason', rejected_reason ? rejected_reason : '');
        } else if (type === 'other') {
          form.append('remarks', remarks);
          form.append('details', details);
          if (selectedStatus !==status) {
            form.append('status', status);
          }
          form.append('rejected_reason', rejected_reason ? rejected_reason : '');
        } else {
          form.append('status', status);
          form.append('rejected_reason', rejected_reason ? rejected_reason : '');
        }
      } else if (showReject) {
        form.append('status', 'rejected');
        form.append('rejected_reason', rejected_reason ? rejected_reason : '');
      } else {
        form.append('status', 'approved');
      }
      this.setState({errorMessage: null}, () => {
        this.props.putPermitStatus(form, id);
      });
    }
  }

  changePermitStatusApprove = (id) => {
    const {formData, showApprove, showDetail, selectedStatus} = this.state;
    const {type, remarks, start_date, end_date, total_days, details, status, rejected_reason} = formData;
    const errors = {};
    const form = new FormData();

    if (showApprove && !rejected_reason) {
      errors.rejected_reason = 'Notes is required';
      this.setState({errorMessage: errors});
    } else if (
      showDetail &&
      type==='sick' &&
      (
        !start_date ||
        !end_date ||
        (moment(start_date) > moment(end_date)) ||
        (moment(end_date) < moment(start_date)) ||
        (!total_days || parseInt(total_days) < 1) ||
        !details ||
        (!status || status==='waiting') ||
        (status==='approved' && !rejected_reason)
      )
    ) {
      if (!start_date) errors.start_date = 'Start date is required';
      if (!end_date) errors.end_date = 'End date is required';
      if (moment(start_date) > moment(end_date)) errors.start_date = `Start date can't greater then end date`;
      if (moment(end_date) < moment(start_date)) errors.end_date = `End date can't lower then start date`;
      if (!total_days || parseInt(total_days) < 1) errors.total_days = `Total days minimal 1 day`;
      if (!details) errors.details = `Leave statement is required`;
      if (!status || status==='waiting') errors.status = `Status is required`;
      if (status==='approved' && !rejected_reason) errors.rejected_reason = `Approved reason is required`;
      this.setState({errorMessage: errors});
    } else if (
      showDetail &&
      type==='other' &&
      (
        !remarks ||
        !details ||
        (!status || status==='waiting') ||
        (status==='approved' && !rejected_reason)
      )
    ) {
      if (!remarks) errors.remarks = `Permit type is required`;
      if (!details) errors.details = `Leave statement is required`;
      if (!status || status==='waiting') errors.status = `Status is required`;
      if (status==='approved' && !rejected_reason) errors.rejected_reason = `Approved reason is required`;
      this.setState({errorMessage: errors});
    } else if (
      showDetail &&
      (type !=='other' || type !=='sick') &&
      (
        (!status || status==='waiting') ||
        (status==='approved' && !rejected_reason)
      )
    ) {
      if (!status || status==='waiting') errors.status = `Status is required`;
      if (status==='approved' && !rejected_reason) errors.rejected_reason = `Approved reason is required`;
      this.setState({errorMessage: errors});
    } else {
      if (showDetail) {
        if (type === 'sick') {
          form.append('type', type);
          form.append('start_date', start_date);
          form.append('end_date', end_date);
          form.append('total_days', total_days);
          form.append('details', details);
          if (selectedStatus !==status) {
            form.append('status', status);
          }
          form.append('rejected_reason', rejected_reason ? rejected_reason : '');
        } else if (type === 'other') {
          form.append('remarks', remarks);
          form.append('details', details);
          if (selectedStatus !==status) {
            form.append('status', status);
          }
          form.append('rejected_reason', rejected_reason ? rejected_reason : '');
        } else {
          form.append('status', status);
          form.append('rejected_reason', rejected_reason ? rejected_reason : '');
        }
      } else if (showApprove) {
        form.append('status', 'approved');
        form.append('rejected_reason', rejected_reason ? rejected_reason : '');
      } else {
        form.append('status', 'approved');
      }
      this.setState({errorMessage: null}, () => {
        this.props.putPermitStatus(form, id);
      });
    }
  }

  handleInputChange = (event) => {
    const {formData, errorMessage} = this.state;
    const {name, value} = event.target;
    this.setState({
      formData: {...formData, [name]: value},
      errorMessage: {...errorMessage, [name]: null},
    }, () => {
      if (name === 'status') {
        this.setState({errorMessage: {...errorMessage, rejected_reason: null}});
      } else if (name === 'type') {
        this.setState({errorMessage: {
          ...errorMessage,
          remarks: null,
          details: null,
          status: null,
          rejected_reason: null,
        }});
      }
    });
  };

  renderDetailDatePermit = (type, date) => {
    if (type === 'other') {
      return (
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item xs={3}>
            <Typography>Date </Typography>
          </Grid>
          <Grid item>
            <Typography> : </Typography>
          </Grid>
          <Grid item xs zeroMinWidth>
            <Typography>{convDate(date, 'D MMMM YYYY')}</Typography>
          </Grid>
        </Grid>
      );
    }
  }

  renderDetailSubTypePermit = (type, remarks) => {
    if (type==='other') {
      const {classes} = this.props;
      const {errorMessage} = this.state;
      return (
        <Grid>
          <MInput
            classNameFC={classes.formControl}
            name="remarks"
            label="Permit Type"
            placeholder="Fill in permit type"
            defaultValue={remarks}
            onBlur={this.handleInputChange}
            error={
              errorMessage && errorMessage.remarks ?
                errorMessage.remarks :
                undefined
            }
            disabled={true}
          />
        </Grid>
      );
    }
  }

  renderDetailRangeDatePermit = (type, start_date, end_date) => {
    if (type === 'sick') {
      const {classes} = this.props;
      const {errorMessage} = this.state;
      return (
        <Grid>
          <Grid>
            <MDatepicker
              className={classes.formControl}
              name="start_date"
              label="Start Date"
              value={start_date}
              onDateChange={this.changeValueDate}
              error={
                errorMessage && errorMessage.start_date ? errorMessage.start_date : undefined
              }
              placeholder="dd-mm-yyyy"
              inputVariant="outlined"
              views={['year', 'month', 'date']}
              fullWidth

            />
          </Grid>
          <Grid>
            <MDatepicker
              className={classes.formControl}
              name="end_date"
              label="End Date"
              value={end_date}
              onDateChange={this.changeValueDate}
              error={
                errorMessage && errorMessage.end_date ? errorMessage.end_date : undefined
              }
              minDate={start_date}
              placeholder="dd-mm-yyyy"
              inputVariant="outlined"
              views={['year', 'month', 'date']}
              fullWidth

            />
          </Grid>
        </Grid>
      );
    }
  }

  renderDetailTotalDaysPermit = (type, total_days) => {
    if (type === 'sick') {
      const {classes} = this.props;
      const {errorMessage} = this.state;
      return (
        <Grid>
          <MInput
            classNameFC={classes.formControl}
            multiline
            type={'number'}
            name="total_days"
            label="Total Days"
            placeholder="Fill in total days"
            defaultValue={total_days}
            onBlur={this.handleInputChange}
            error={
              errorMessage && errorMessage.total_days ?
                errorMessage.total_days :
                undefined
            }
          />
        </Grid>
      );
    }
  }

  renderDetailValueAttachmentPermit = (imageUri) => {
    if (imageUri) {
      const {classes} = this.props;
      return (
        <Link href={imageUri} target="_blank" rel="noopener noreferrer">
          <CardMedia
            title="Attachment"
            image={imageUri}
            className={classes.imgSize}
          />
        </Link>
      );
    } else {
      return <Typography>No Attachment</Typography>;
    }
  }

  renderDetailAttachmentPermit = (type, image) => {
    if (type === 'sick') {
      const {classes} = this.props;
      const {errorMessage} = this.state;
      return (
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item xs={3}>
            <Typography>Attachment </Typography>
          </Grid>
          <Grid item>
            <Typography> : </Typography>
          </Grid>
          <Grid item xs zeroMinWidth>
            {this.renderDetailValueAttachmentPermit(image)}
          </Grid>
        </Grid>
      );
    }
  }

  changeValueDate = (parms) => {
    const {name, value} = parms;
    const {formData} = this.state;
    const {end_date} = formData;
    const val_date = new Date(value);
    const val_end_date = new Date(end_date);
    if (name === 'start_date') {
      if (val_date > val_end_date) {
        this.setState({
          formData: {
            ...this.state.formData,
            start_date: val_date,
            end_date: val_date,
          },
        });
      } else {
        this.setState({
          formData: {
            ...this.state.formData,
            start_date: val_date,
          },
        });
      }
    } else {
      this.setState({
        formData: {
          ...this.state.formData,
          end_date: val_date,
        },
      });
    }
  }


  render() {
    const {classes, permitData, permitMeta, isLoading} = this.props;
    const {resetDatatable, resetPage} = this.state;
    const columns = [
      {name: 'no', label: 'No', display: true},
      {
        name: 'name',
        label: 'Name',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'total_days',
        label: 'Total Days',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'start_date',
        label: 'Start Date',
        display: true,
        customBodyRender: (value) => {
          return <div style={{whiteSpace: 'nowrap'}}>{value ? convDate(value, 'DD-MM-YYYY') : '-'}</div>;
        },
      },
      {
        name: 'end_date',
        label: 'End Date',
        display: true,
        customBodyRender: (value) => {
          return <div style={{whiteSpace: 'nowrap'}}>{value ? convDate(value, 'DD-MM-YYYY') : '-'}</div>;
        },
      },
      {
        name: 'type',
        label: 'Type',
        display: true,
        customBodyRender: (value) => {
          return value ? convText(value) : '';
        },
      },
      {
        name: 'status',
        label: 'Status',
        display: true,
        customBodyRender: (value) =>
          value ? value.charAt(0).toUpperCase() + value.slice(1) : '-',
      },
      {
        name: 'approvedBy',
        label: 'Approved By',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'details',
        label: 'Leave Statement',
        display: false,
      },
      {
        name: 'remarks',
        label: 'Permit Type',
        display: false,
      },
      {name: 'id', display: false},
    ];
    return (
      <div className={classes.root}>
        {this.renderFilter()}
        <Datatable
          creatable={false}
          title={this.renderTableTitle()}
          loading={isLoading}
          dataSource={permitData}
          total={permitMeta.total}
          page={permitMeta.page}
          columns={columns}
          handleReload={(params) => this.handleReload(params)}
          handleDetail={(params) => this.toggleModal('detail', params)}
          customActions={(params) => this.renderActions(params)}
          toggleResetAll={resetDatatable}
          toggleResetPage={resetPage}
          manualNumbering={true}
        />
        {this.renderModalDetail()}
        {this.renderSnackbar()}
        {this.renderModalRejected()}
        {this.renderModalApproved()}
      </div>
    );
  }
}

const mapStateToProps = ({permit}) => ({
  isLoading: permit.isLoading,
  isError: permit.isError,
  isSuccess: permit.isSuccess,
  message: permit.message,
  permitData: permit.permitData,
  permitMeta: permit.permitMeta,
  trigger: permit.trigger,

  isLoadingPut: permit.isLoadingPut,
  isSuccessPut: permit.isSuccessPut,
  isFailurePut: permit.isFailurePut,
  isErrorPut: permit.isErrorPut,
  messagePut: permit.messagePut,
  status: permit.status,
  exportLoading: permit.exportLoading,
  exportData: permit.exportData,
});
const mapDispatchToProps = (dispatch) => ({
  getPermitHistory: (params) => dispatch(getPermitHistory(params)),
  putPermitStatus: (param, id) => dispatch(putPermitStatus(param, id)),
  checkStatus: () => dispatch(checkStatus()),
  checkStatusUser: () => dispatch(checkStatusUser()),
  updateExportNotification: (params) => dispatch(updateExportNotification(params)),
  exportExcel: (params) => dispatch(exportExcel(params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(Permit));
