/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable indent */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  withStyles,
  Paper,
  Grid,
  createTheme,
  MuiThemeProvider,
  Tooltip,
  IconButton,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import {HelpOutline, Send} from '@material-ui/icons';
import styles from './styles';
import {MInput, MButton, MKeyboardTimePicker, MSelect} from '../../../components/form';
import {toast, isArray, isObject, checkSuperadmin} from '../../../helpers';
import {GENERAL_SETTING_STATE as STATE} from '../../../redux/generalSetting/generalSetting.types';
import {
  getGeneralSetting,
  updateGeneralSetting,
} from './../../../redux/generalSetting/generalSetting.actions';
import {
  checkStatus,
} from './../../../redux/userManagement/userManagement.actions';
import _ from 'lodash';


class WorkingTimeSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        check_in: null,
        default_check_in: null,
        working_time: null,
        tolerance_time: null,
        time_zone: null,
        take_photo_office: null,
      },
      formDataUpdate: {
        check_in: null,
        default_check_in: null,
        working_time: null,
        tolerance_time: null,
        time_zone: null,
        take_photo_office: null,
      },
      settings: [],
      message: false,
      showModalJobTitle: false,
      showModalGroupEmail: false,
      errorMessage: null,
    };
  }

  selectFieldTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiInputBase: {
        root: {
          lineHeight: '1em',
          fontSize: '14px',
        },
      },
    },
  });

  tooltipTheme = () => createTheme({
    palette: {
      secondary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiSvgIcon: {
        root: {
          fontSize: 16,
          marginTop: 1,
        },
      },
      MuiIconButton: {
        root: {
          padding: 0,
        },
      },
    },
  })

  UNSAFE_componentWillMount() {
    this.props.getGeneralSetting();
    this.props.checkStatus();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {message, trigger, data, jobTrigger, jobMessage, isError, menusData, triggerUser} = nextProps;

    if (trigger === STATE.UPDATE_SUCCESS) {
      toast('success', message);
      this.props.getGeneralSetting();
      this.props.checkStatus();
    }

    if (trigger === STATE.FETCH_ALL_SUCCCESS) {
      const filtered = this.filterSetting(data);
      const checkInSetting = filtered && filtered[0] ? filtered[0] : null;
      const default_check_in = filtered && filtered[1] ? filtered[1] : null;
      const tolerance_time = filtered && filtered[2] ? filtered[2] : null;
      const working_time = filtered && filtered[3] ? filtered[3] : null;
      const time_zone = filtered && filtered[4] ? filtered[4] : null;
      const take_photo_office = filtered && filtered[5] ? filtered[5] : null;
      // if (checkInSetting && checkInSetting.value) checkInSetting.value = moment(checkInSetting.value, 'hh:mm');
      this.setState({
        settings: filtered,
        formData: {
          check_in: checkInSetting,
          default_check_in: default_check_in,
          working_time: working_time,
          tolerance_time: tolerance_time,
          time_zone: time_zone,
          take_photo_office: take_photo_office,
        },
        formDataUpdate: {
          check_in: checkInSetting,
          default_check_in: default_check_in,
          working_time: working_time,
          tolerance_time: tolerance_time,
          time_zone: time_zone,
          take_photo_office: take_photo_office,
        },
      });
    }

    if (this.props.trigger !== trigger && trigger === STATE.UPDATE_FAILED && !isObject(message)) {
      toast('error', message);
      this.props.getGeneralSetting();
    }

    if (isError && message) {
      if (isObject(message)) this.setState({errorMessage: message});
      if (!isObject(message)) toast('error', message);
    }
  }

  filterSetting = (data) => {
    const filter = (data, key) => {
      const result = data.filter((value) => value.key === key);
      return isArray(result) ? result[0] : null;
    };
    const checkInSetting = filter(data, 'check_in');
    const default_check_in = filter(data, 'default_check_in');
    const working_time = filter(data, 'working_time');
    const tolerance_time = filter(data, 'tolerance_time');
    const time_zone = filter(data, 'time_zone');
    const take_photo_office = filter(data, 'take_photo_office');

    return [checkInSetting, default_check_in, tolerance_time, working_time, time_zone, take_photo_office];
  }

  handleSubmit = () => {
    const {formDataUpdate, errorMessage} = this.state;
    const {menusData} = this.props;
    const converted = [];
    let newVal;

    if (!menusData.shift) {
      const newFormDataUpdate = [
        formDataUpdate.default_check_in,
        formDataUpdate.working_time,
        formDataUpdate.tolerance_time,
        formDataUpdate.time_zone,
      ];

      const newDataUpdate = newFormDataUpdate.filter((values) => {
        return values !==null;
      });
      newDataUpdate.map((val, key) => {
        if (val.key === 'default_check_in') {
          newVal = val.value ? `{"start_time": "${val.value.start_time}", "end_time": "${val.value.end_time}"}` : null;
        }

        if (val.key === 'tolerance_time') {
          newVal = val.value;
        }

        if (val.key === 'working_time') {
          newVal = val.value;
        }

        if (val.key === 'time_zone') {
          newVal = val.value;
        }

        if (val.key === 'take_photo_office') {
          newVal = val.value;
        }

        converted.push({
          key: val.key,
          label: val.label,
          value: newVal,
          group: val.group,
        });
      });
    } else {
      const newFormDataUpdate = [
        formDataUpdate.default_check_in,
        formDataUpdate.working_time,
        formDataUpdate.tolerance_time,
        formDataUpdate.time_zone,
        formDataUpdate.take_photo_office,
      ];

      const newDataUpdate = newFormDataUpdate.filter((values) => {
        return values !==null;
      });
      newDataUpdate.map((val, key) => {
        if (val.key === 'default_check_in') {
          newVal = val.value ? `{"start_time": "${val.value.start_time}", "end_time": "${val.value.end_time}"}` : null;
        }

        if (val.key === 'tolerance_time') {
          newVal = val.value;
        }

        if (val.key === 'working_time') {
          newVal = val.value;
        }

        if (val.key === 'time_zone') {
          newVal = val.value;
        }

        if (val.key === 'take_photo_office') {
          newVal = val.value;
        }

        converted.push({
          key: val.key,
          label: val.label,
          value: newVal,
          group: val.group,
        });
      });
    }

    if (Object.values(errorMessage).every((x) => x === null)) {
      const params = {data: converted};
      this.props.updateGeneralSetting(params);
    }
  }

  handleInputChangeSetting = (type, params) => {
    let name;
    let value;
    if (type === 'text') {
      name = params.target.name;
      value = params.target.value;
    } else if (type === 'multiple') {
      name = params.name;
      value = params.value;
    } else if (type === 'datetime') {
      name = params.name;
      value = moment(params.value).format('HH:mm');
      if (value === 'Invalid date') {
        this.setState({
          errorMessage: {
            ...this.state.errorMessage,
            datetime: 'Time is not valid',
          },
        });
      } else {
        this.setState({
          errorMessage: {
            ...this.state.errorMessage,
            datetime: null,
          },
        });
      }
    }
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: {
          ...this.state.formData[name],
          value,
        },
      },
      formDataUpdate: {
        ...this.state.formDataUpdate,
        [name]: {
          ...this.state.formDataUpdate[name],
          value,
        },
      },
    });
  };

  handleInputChange = (event) => {
    const {name, value} = event.target;
    const re = /^[0-8\b]{1}$/;

    if (value === '' || re.test(value)) {
      this.setState({
        formData: {
          ...this.state.formData,
          [name]: {
            ...this.state.formData[name],
            value,
          },
        },
        formDataUpdate: {
          ...this.state.formDataUpdate,
          [name]: {
            ...this.state.formDataUpdate[name],
            value,
          },
        },
        errorMessage: {
          ...this.state.errorMessage,
          [name]: null,
        },
      });
    } else {
      this.setState({
        errorMessage: {
          ...this.state.errorMessage,
          [name]: 'Working time can only be 0 - 8 hours',
        },
        formData: {
          ...this.state.formData,
          [name]: {
            ...this.state.formData[name],
            value,
          },
        },
        formDataUpdate: {
          ...this.state.formDataUpdate,
          [name]: {
            ...this.state.formDataUpdate[name],
            value,
          },
        },
      });
    }
  };

  handleInputChangeTimezone = (event) => {
    const {name, value} = event.target;
    const re = /^[0-9\b]+$/;

    if (value === '' || re.test(value)) {
      this.setState({
        formData: {
          ...this.state.formData,
          [name]: {
            ...this.state.formData[name],
            value,
          },
        },
        formDataUpdate: {
          ...this.state.formDataUpdate,
          [name]: {
            ...this.state.formDataUpdate[name],
            value,
          },
        },
        errorMessage: {
          ...this.state.errorMessage,
          [name]: null,
        },
      });
    } else {
      this.setState({
        errorMessage: {
          ...this.state.errorMessage,
          [name]: 'Time Zone is not valid',
        },
      });
    }
  };

  handleInputChangeDate = (datetype, event) => {
    const {value, name} = event;
    const {errorMessage} = this.state;
    const {menusData} = this.props;
    if (datetype === 'start_time') {
      if (moment(value).format('HH:mm') === 'Invalid date') {
        this.setState({
          errorMessage: {
            ...errorMessage,
            start_time: 'Time is not valid',
          },
        });
      } else {
        this.setState({
          errorMessage: {
            ...errorMessage,
            start_time: null,
          },
        });
      }
    } else if (datetype === 'end_time') {
      if (moment(value).format('HH:mm') === 'Invalid date') {
        this.setState({
          errorMessage: {
            ...errorMessage,
            end_time: 'Time is not valid',
          },
        });
      } else {
        this.setState({
          errorMessage: {
            ...errorMessage,
            end_time: null,
          },
        });
      }
    }
    if (!menusData.shift) {
      this.setState({
        formData: {
          ...this.state.formData,
          default_check_in: {
            ...this.state.formData.default_check_in,
            value: {
              ...this.state.formData.default_check_in.value,
              [datetype]: moment(value).format('HH:mm'),
            },
          },
        },
        formDataUpdate: {
          ...this.state.formDataUpdate,
          default_check_in: {
            ...this.state.formData.default_check_in,
            value: {
              ...this.state.formDataUpdate.default_check_in.value,
              [datetype]: moment(value).format('HH:mm'),
            },
          },
        },
      });
    } else {
      this.setState({
        formData: {
          ...this.state.formData,
          default_check_in: {
            ...this.state.formData.default_check_in,
            value: {
              ...this.state.formData.default_check_in.value,
              [datetype]: moment(value).format('HH:mm'),
            },
          },
        },
        formDataUpdate: {
          ...this.state.formDataUpdate,
          default_check_in: {
            ...this.state.formData.default_check_in,
            value: {
              ...this.state.formDataUpdate.default_check_in.value,
              [datetype]: moment(value).format('HH:mm'),
            },
          },
        },
      });
    }
  }

  handleInputChangeTime = (time_zone, event) => {
    const {value, name} = event.target;
    const {menusData} = this.props;
    if (!menusData.shift) {
      this.setState({
        formData: {
          ...this.state.formData,
          time_zone: {
            ...this.state.formData.time_zone,
            value: value,
          },
        },
        formDataUpdate: {
          ...this.state.formDataUpdate,
          time_zone: {
            ...this.state.formData.time_zone,
            value: value,
          },
        },
      });
    } else {
      this.setState({
        formData: {
          ...this.state.formData,
          time_zone: {
            ...this.state.formData.time_zone,
            value: value,
          },
        },
        formDataUpdate: {
          ...this.state.formDataUpdate,
          time_zone: {
            ...this.state.formData.time_zone,
            value: value,
          },
        },
      });
    }
  }

  handleSwitch = (event) => {
    const {checked} = event.target;
    const newValue = checked ? 'true' : 'false';

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        take_photo_office: {
          ...prevState.formData.take_photo_office,
          value: checked,
        },
      },
      formDataUpdate: {
        ...prevState.formDataUpdate,
        take_photo_office: {
          ...prevState.formDataUpdate.take_photo_office,
          value: newValue,
        },
      },
    }));
  };

  renderForm = (settingArray) => {
    const renderMethodOptions = () => {
      const options = [
        {id: '(UTC+07:00) Western Indonesia Time', name: '(UTC+07:00) Western Indonesia Time'},
        {id: '(UTC+08:00) Central Indonesia Time', name: '(UTC+08:00) Central Indonesia Time'},
        {id: '(UTC+09:00) Eastern Indonesia Time', name: '(UTC+09:00) Eastern Indonesia Time'},
      ];
      return options;
    };
    const {classes, menusData} = this.props;
    const {errorMessage} = this.state;
    const {job_title, leaderboard_job_title, default_check_in, working_time, tolerance_time, time_zone, take_photo_office} = this.state.formData;
    const settingArr = _.remove(settingArray, {id: undefined});
    const result = settingArray.map((setting) => {
      if (setting) {
        const {id, key, label} = setting;
        if (!menusData.shift) {
          if (key === 'default_check_in') {
            return (
              <div key={id}>
                <h5 className={classes.subTitle}>Office Working Setting</h5>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <MKeyboardTimePicker
                      inputVariant="outlined"
                      className={classes.formControl}
                      label={'Minimum Check-In Time'}
                      name={key}
                      value={default_check_in ? default_check_in.value.start_time !== 'undefined' ? moment(default_check_in.value.start_time, 'hh:mm') : moment('08:00', 'hh:mm') : moment('08:00', 'hh:mm') }
                      onTimeChange={(event) => this.handleInputChangeDate('start_time', event)}
                      error={errorMessage && errorMessage.start_time ? errorMessage.start_time : undefined}
                      ampm={false}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MKeyboardTimePicker
                      inputVariant="outlined"
                      className={classes.formControl}
                      label={'Maximum Check-In Time'}
                      name={key}
                      value={default_check_in ? default_check_in.value.end_time !== 'undefined' ? moment(default_check_in.value.end_time, 'hh:mm') : moment('17:00', 'hh:mm') : moment('17:00', 'hh:mm')}
                      onTimeChange={(event) => this.handleInputChangeDate('end_time', event)}
                      error={errorMessage && errorMessage.end_time ? errorMessage.end_time : undefined}
                      ampm={false}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MuiThemeProvider theme={this.selectFieldTheme()}>
                      <MSelect
                        variant="outlined"
                        // className={classes.formControl}
                        name="time_zone"
                        label="Time Zone"
                        keyPair={['id', 'name']}
                        options={renderMethodOptions()}
                        value={time_zone ? time_zone.value : null}
                        onChange={(event) => this.handleInputChangeTime('time_zone', event)}
                    />
                    </MuiThemeProvider>
                  </Grid>
                </Grid>
              </div>
            );
          } else if (key === 'tolerance_time') {
            return (
              <div key={id}>
                <h5 className={classes.subTitle}>Late Check-In Hours</h5>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <MKeyboardTimePicker
                      inputVariant="outlined"
                      className={classes.formControl}
                      label={'Maximum Check In'}
                      name={key}
                      value={tolerance_time ? tolerance_time.value !== 'undefined' ? moment(tolerance_time.value, 'hh:mm') : moment('10:00', 'hh:mm') : moment('10:00', 'hh:mm') }
                      onTimeChange={(event) => this.handleInputChangeSetting('datetime', event)}
                      error={errorMessage && errorMessage.datetime ? errorMessage.datetime : undefined}
                      ampm={false}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </div>
            );
          } else if (key === 'working_time') {
            return (
              <div key={id}>
                <h5 className={classes.subTitle}>Total Working Hours</h5>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <MInput
                      inputVariant="outlined"
                      classNameFC={classes.formControl}
                      label={'Working Hours'}
                      name={key}
                      value={working_time.value ? working_time.value : null}
                      onChange={this.handleInputChange}
                      fullWidth
                      error={errorMessage && errorMessage.working_time ? errorMessage.working_time : undefined}
                      type='number'
                    />
                  </Grid>
                </Grid>
              </div>
            );
          }
        } else {
          if (key === 'default_check_in') {
            return (
              <div key={id}>
                <h5 className={classes.subTitle}>Office Working Setting</h5>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <MKeyboardTimePicker
                        inputVariant="outlined"
                        className={classes.formControl}
                        label={'Minimum Check-In Time'}
                        name={key}
                        value={default_check_in ? default_check_in.value.start_time !== 'undefined' ? moment(default_check_in.value.start_time, 'hh:mm') : moment('08:00', 'hh:mm') : moment('08:00', 'hh:mm') }
                        onTimeChange={(event) => this.handleInputChangeDate('start_time', event)}
                        error={errorMessage && errorMessage.start_time ? errorMessage.start_time : undefined}
                        ampm={false}
                        fullWidth
                      />
                      <MuiThemeProvider theme={this.tooltipTheme()}>
                        <Tooltip title="Your employees will only be able to check-in starting from this time." style={{marginLeft: 6, bottom: 1}}>
                          <IconButton>
                            <HelpOutline />
                          </IconButton>
                        </Tooltip>
                      </MuiThemeProvider>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <MKeyboardTimePicker
                        inputVariant="outlined"
                        className={classes.formControl}
                        label={'Maximum Check-In Time'}
                        name={key}
                        value={default_check_in ? default_check_in.value.end_time !== 'undefined' ? moment(default_check_in.value.end_time, 'hh:mm') : moment('17:00', 'hh:mm') : moment('17:00', 'hh:mm')}
                        onTimeChange={(event) => this.handleInputChangeDate('end_time', event)}
                        error={errorMessage && errorMessage.end_time ? errorMessage.end_time : undefined}
                        ampm={false}
                        fullWidth
                      />
                      <MuiThemeProvider theme={this.tooltipTheme()}>
                        <Tooltip title="Your employees will not be able to check-in starting from this time." style={{marginLeft: 6, bottom: 1}}>
                          <IconButton>
                            <HelpOutline />
                          </IconButton>
                        </Tooltip>
                      </MuiThemeProvider>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <MuiThemeProvider theme={this.selectFieldTheme()}>
                      <MSelect
                        variant="outlined"
                        // className={classes.formControl}
                        name="time_zone"
                        label="Time Zone"
                        keyPair={['id', 'name']}
                        options={renderMethodOptions()}
                        value={time_zone ? time_zone.value : null}
                        onChange={(event) => this.handleInputChangeTime('time_zone', event)}
                      />
                    </MuiThemeProvider>
                  </Grid>
                </Grid>
              </div>
            );
          } else if (key === 'tolerance_time') {
            return (
              <div key={id}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <h5 className={classes.subTitle}>Late Check-In Hours</h5>
                  <MuiThemeProvider theme={this.tooltipTheme()}>
                    <Tooltip title="Your employees will be considered as late for check-in starting from this time." style={{marginLeft: 6, marginTop: 2}}>
                      <IconButton>
                        <HelpOutline />
                      </IconButton>
                    </Tooltip>
                  </MuiThemeProvider>
                </div>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <MKeyboardTimePicker
                      inputVariant="outlined"
                      className={classes.formControl}
                      label={'Maximum Check In'}
                      name={key}
                      value={tolerance_time ? tolerance_time.value !== 'undefined' ? moment(tolerance_time.value, 'hh:mm') : moment('10:00', 'hh:mm') : moment('10:00', 'hh:mm') }
                      onTimeChange={(event) => this.handleInputChangeSetting('datetime', event)}
                      error={errorMessage && errorMessage.datetime ? errorMessage.datetime : undefined}
                      ampm={false}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </div>
            );
          } else if (key === 'working_time') {
            return (
              <div key={id}>
                <h5 className={classes.subTitle}>Total Working Hours</h5>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <MInput
                      inputVariant="outlined"
                      classNameFC={classes.formControl}
                      label={'Working Hours'}
                      name={key}
                      value={working_time.value ? working_time.value : null}
                      onChange={this.handleInputChange}
                      fullWidth
                      error={errorMessage && errorMessage.working_time ? errorMessage.working_time : undefined}
                      type='number'
                    />
                  </Grid>
                </Grid>
              </div>
            );
          } else if (key === 'take_photo_office') {
            return (
              <div key={id}>
                <h5 className={classes.subTitle}>Take A Photo</h5>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          name={key}
                          checked={take_photo_office?.value}
                          onChange={(event) => this.handleSwitch(event)}
                        />
                      }
                      label={
                        <div style={{display: 'flex', alignItems: 'center'}}>
                          <Typography>Take A Photo at Office Working</Typography>
                        </div>
                      }
                    />
                  </FormGroup>
                </Grid>
              </div>
            );
          }
        }
        // else if (key === 'group_email') {
        //   const {formData} = this.state;
        //   const {group_email} = formData;
        //   const email_values = group_email.value.length > 0 ? group_email.value.map((val) => {
        //     return val.email;
        //   }) : [];
        //   return (
        //     <div key={id}>
        //       <h5>Group Emails</h5>
        //       <Grid container>
        //         <Grid item xs={10}>
        //           <SelectMultiple
        //             className={classes.formControl}
        //             label={label}
        //             name={key}
        //             placeholder="Select emails for your company"
        //             keyPair={['email', 'email']}
        //             data={group_email.value}
        //             value={email_values}
        //             onChange={(event) => {
        //               const newValue = [];
        //               if (event.length > 0) {
        //                 event.map((val) => {
        //                   return newValue.push({email: val});
        //                 });
        //               }
        //               this.setState({
        //                 formData: {
        //                   ...this.state.formData,
        //                   group_email: {
        //                     ...this.state.formData.group_email,
        //                     value: newValue,
        //                   },
        //                 },
        //                 formDataUpdate: {
        //                   ...this.state.formDataUpdate,
        //                   group_email: {
        //                     ...this.state.formDataUpdate.group_email,
        //                     value: newValue,
        //                   },
        //                 },
        //               });
        //             }}
        //           />
        //         </Grid>
        //         <Grid item xs={2} className={classes.btnAddContainer}>
        //           <MButton
        //             className={classes.btnAdd}
        //             label="Add New"
        //             color="primary"
        //             size='small'
        //             icon={<Add />}
        //             loading={jobLoading}
        //             onClick={this.toggleModalGroupEmail}
        //           />
        //         </Grid>
        //         {this.renderModalGroupEmail()}
        //       </Grid>
        //     </div>
        //   );
        // } else if (key === 'notification') {
        //   const dataNotif = this.state.formData.notification.value;
        //   return (
        //     <div key={id}>
        //       <h5 className={classes.subTitle}>Notification</h5>
        //       <Grid container>
        //       {dataNotif.map((valNotif) => {
        //         const checkedNotifPush = valNotif.push === 'true' ? true : valNotif.push === 'false' ? false : valNotif.push;
        //         const checkedNotifEmail = valNotif.email === 'true' ? true : valNotif.email === 'false' ? false : valNotif.email;
        //         return (
        //             <Grid item xs={4} spacing={2}>
        //               <MCheckboxGroup
        //                 label={`Feature ${valNotif.feature.replace(/\b\w/g, (c) => c.toUpperCase())}`}
        //               >
        //                 <MCheckboxItem
        //                   name={`push_${valNotif.feature}`}
        //                   label={`Push`}
        //                   checked={checkedNotifPush}
        //                   onChange={this.handleCheckBoxNotif}
        //                 />
        //                 <MCheckboxItem
        //                   name={`email_${valNotif.feature}`}
        //                   label={`Email`}
        //                   checked={checkedNotifEmail}
        //                   onChange={this.handleCheckBoxNotif}
        //                 />
        //               </MCheckboxGroup>
        //             </Grid>
        //         );
        //       })}
        //       </Grid>
        //     </div>
        //   );
        // }
      }
    });
    return result;
  }


  render() {
    const {classes, data, isLoading, menusData} = this.props;
    const {settings, errorMessage} = this.state;
    return (
      <div className={classes.root}>
        <Paper variant='outlined' className={classes.paper}>
          <form>
            {
              settings !== [] ?
              this.renderForm(settings) : ''
            }
            <div className={classes.btnContainer}>
              <MButton
                className={classes.defaultBtn}
                label="Update Setting"
                icon={<Send/>}
                onClick={() => this.handleSubmit()}
                loading={isLoading && data !== [] ? true : false}
              />
            </div>
          </form>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = ({auth, generalSetting, jobTitle, userManagement}) => {
  return ({
    isSuperAdmin: checkSuperadmin(auth),
    organization: auth.data.organization,
    data: generalSetting.data,
    isLoading: generalSetting.isLoading,
    isSuccess: generalSetting.isSuccess,
    isError: generalSetting.isError,
    menusData: auth.data.menus,
    message: generalSetting.message,
    trigger: generalSetting.trigger,
  });
};

const mapDispatchToProps = (dispatch) => ({
  getGeneralSetting: (key) => dispatch(getGeneralSetting(key)),
  updateGeneralSetting: (params) => dispatch(updateGeneralSetting(params)),
  checkStatus: (params) => dispatch(checkStatus(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WorkingTimeSettings));
