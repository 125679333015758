import {
  ClickAwayListener,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import {MoreVert} from '@material-ui/icons';
import React, {useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import PoppupShareEvent from '../../components/poppup-share-event';

const eventWeb = process?.env?.REACT_APP_PAGII_EVENT_WEB || 'pagii.co/';

const ActionMenu = ({handleDetail, handleCancelEvent, data, toggleAnnouncement, handleDuplicated}) => {
  const id = data[1];
  const status = data[7];
  const history = useHistory();
  const anchorRef = useRef();
  const [open, setOpen] = useState(false);
  const [showPopupShare, setShowPopupShare] = useState(false);

  const handleClick = () => {
    setOpen((prevState) => !prevState);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const togglePopupShare = () => setShowPopupShare((prevState) => !prevState);

  return (
    <>
      <PoppupShareEvent
        open={showPopupShare}
        toggle={togglePopupShare}
        link={eventWeb + 'events/' + id}
      />

      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            ref={anchorRef}
          >
            <MoreVert />
          </IconButton>

          <Popper open={open} anchorEl={anchorRef.current} transition>
            {({TransitionProps, placement}) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  zIndex: 1,
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList>
                      <MenuItem onClick={() => handleDetail(id)}>
                        Detail
                      </MenuItem>

                      {['on_review', 'blocked', 'ended', 'canceled'].includes(status) ? null :
                      <MenuItem onClick={() => history.push('/event-management/update', {isEdit: true, id})}>
                        Update
                      </MenuItem>}

                      {status !== 'active' ? null : <>
                        <MenuItem onClick={() => toggleAnnouncement(id)}>Announcement</MenuItem>
                        <MenuItem onClick={togglePopupShare}>Share Event</MenuItem>

                        <MenuItem
                          onClick={() => handleCancelEvent(data)}
                          style={{color: 'crimson'}}
                          >
                          Cancel Event
                        </MenuItem>
                      </>}
                      <MenuItem onClick={() => handleDuplicated(id)}>
                        Duplicate
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    </>
  );
};

export default ActionMenu;
