/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import {Dialog, DialogActions, Grid, Box, withStyles, TextField} from '@material-ui/core';
import {MButton, ModalTitle} from '../../../../components/form';
import styles from './styles';
import {useDispatch, useSelector} from 'react-redux';
import {
  togglePopupDuplicated,
  getEventDetail,
  duplicatedEvent,
} from '../../../../redux/eventManagement/eventManagement.actions';

function PopUp({classes, title, id, fetchList}) {
  const dispatch = useDispatch();
  const [evenName, setEventName] = useState('');
  const {isLoading, showPopupDuplicated, eventDetail} = useSelector((state) => state?.eventManagement);
  const handleClose = () => dispatch(togglePopupDuplicated());

  const removeSlug = (customFields) => {
    return customFields.map((field) => {
      const {slug, options, ...rest} = field;
      const cleanedField = {...rest};
      if (options && options.length > 0) {
        cleanedField.options = options;
      }
      return cleanedField;
    });
  };

  const handleSubmitDuplicated = (e) => {
    e.preventDefault();
    const duplicatedEventData = {
      title: evenName ? evenName : `${eventDetail.title} (copy)`,
      description: eventDetail.description,
      date_start: eventDetail.date_start,
      date_end: eventDetail.date_end,
      expired_registration: eventDetail.expired_registration,
      location: eventDetail.location,
      type: eventDetail.type,
      payment_type: eventDetail.payment_type,
      maximum_participants: eventDetail.maximum_participants,
      status: 'draft',
      currency: eventDetail.currency,
      link: eventDetail.link,
      package: eventDetail.package,
      photo: eventDetail.photo,
      custom_field: removeSlug(eventDetail.custom_field),
    };
    fetchList();
    handleClose();
    try {
      dispatch(duplicatedEvent(duplicatedEventData));
    } catch (error) {
      console.log('error', error);
    }
  };


  useEffect(() => {
    if (id) {
      dispatch(getEventDetail(id));
    }
    }, [id]);

  useEffect(() => {
    if (eventDetail) {
      setEventName( `${eventDetail.title} (copy)`);
    }
  }, [eventDetail]);


const handleChange = (e) => {
  const value = e.target.value;
  setEventName(value);
};
  return (
    <Dialog
      className={classes.modal}
      open={showPopupDuplicated}
      maxWidth="md"
    >
      <ModalTitle title={title} onClose={handleClose} />
      <div className={classes.DialogContent}>Duplicated this event?</div>
      {/* <DialogContent className={classes.dialogContent}> */}
        <TextField
          placeholder="Input Event Name"
          className={classes.fieldEventName}
          fullWidth
          InputProps={{
            disableUnderline: true,
            fullWidth: true,
          }}
          variant="outlined"
          value={evenName}
          onChange={handleChange}
        />
      {/* </DialogContent> */}
      <DialogActions className={classes.dialogFooter}>
        <Grid container direction="row" justifyContent="flex-end">
          <Box>
            <MButton
              type="button"
              label="cancel"
              className={classes.btnCancel}
              style={{marginRight: 15}}
              onClick={handleClose}
              loading={isLoading}
            />

            <MButton
              type="button"
              className={classes.btnPublish}
              label="Oke"
              onClick={handleSubmitDuplicated}
              loading={isLoading}
              disabled={!eventDetail || isLoading}
            />
          </Box>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(PopUp);
