/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable indent */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles, Paper, Grid} from '@material-ui/core';
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import moment from 'moment';
import {Send, Add, Delete} from '@material-ui/icons';
import styles from './styles';
import {MInput, MButton, MKeyboardTimePicker} from '../../../components/form';
import {toast, isArray, isObject, checkSuperadmin, showPopup} from '../../../helpers';
import {GENERAL_SETTING_STATE as STATE} from '../../../redux/generalSetting/generalSetting.types';
import {getGeneralSetting, updateGeneralSetting} from './../../../redux/generalSetting/generalSetting.actions';
import {checkStatus as checkStatusUser} from '../../../redux/userManagement/userManagement.actions';
import {checkStatus} from '../../../redux/organization/organization.actions';
import _ from 'lodash';

class ShiftSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        term_and_condition: null,
        job_title: [],
        check_in: [],
        group_email: null,
        notification: null,
        shifts: [],
        shift_setting: null,
      },
      formDataUpdate: {
        term_and_condition: null,
        job_title: [],
        check_in: [],
        group_email: null,
        notification: null,
        shifts: [],
        shift_setting: null,
      },
      settings: [],
      message: false,
      errorMessage: null,
      errorMessageRequired: null,
    };
  }

  withMuiTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    MuiInputBase: {
      root: {
        color: '#656464',
        fontSize: '14px',
        fontStyle: 'normal',
      },
    },
  })
  getMuiTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiFormControl: {
        fullWidth: {
          width: '250px',
        },
      },
      MuiInputBase: {
        root: {
          color: '#656464',
          fontSize: '14px',
          fontStyle: 'normal',
        },
      },
      MuiButton: {
        contained: {
          'boxShadow': 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
  })

  UNSAFE_componentWillMount() {
    this.props.getGeneralSetting();
    this.props.checkStatus();
    this.props.checkStatusUser();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {message, trigger, data} = nextProps;

    if (trigger === STATE.UPDATE_SUCCESS) {
      toast('success', message);
      this.props.getGeneralSetting();
    }

    if (trigger === STATE.FETCH_ALL_SUCCCESS) {
      const filtered = this.filterSetting(data);
      const tncSetting = filtered && filtered[0] ? filtered[0] : null;
      const jobSetting = filtered && filtered[1] ? filtered[1] : null;
      const checkInSetting = filtered && filtered[2] ? filtered[2] : null;
      const GroupEmail = filtered && filtered[3] ? filtered[3] : null;
      const notification = filtered && filtered[4] ? filtered[4] : null;
      const shiftSetting = filtered && filtered[5] ? filtered[5] : null;

      this.setState({
        settings: filtered,
        formData: {
          term_and_condition: tncSetting,
          job_title: jobSetting,
          check_in: checkInSetting,
          group_email: GroupEmail,
          notification: notification,
          shifts: checkInSetting.value,
          shift_setting: shiftSetting,
        },
        formDataUpdate: {
          term_and_condition: tncSetting,
          job_title: jobSetting,
          check_in: checkInSetting,
          group_email: GroupEmail,
          notification: notification,
          shifts: checkInSetting.value,
          shift_setting: shiftSetting,
        },
      });
    }

    if (this.props.trigger !== trigger && trigger === STATE.UPDATE_FAILED && !isObject(message)) {
      toast('error', message);
      this.props.getGeneralSetting();
    }
  }

  filterSetting = (data) => {
    const filter = (data, key) => {
      const result = data.filter((value) => value.key === key);
      return isArray(result) ? result[0] : null;
    };

    const tncSetting = filter(data, 'term_and_condition');
    const jobSetting = filter(data, 'job_title');
    const checkInSetting = filter(data, 'check_in');
    const groupEmail = filter(data, 'group_email');
    const notification = filter(data, 'notification');
    const shiftSetting = filter(data, 'shift_setting');

    return [tncSetting, jobSetting, checkInSetting, groupEmail, notification, shiftSetting];
  }

  handleSubmit = () => {
    const shiftData = this.state.formDataUpdate.shifts;
    const regExp = /\S/g;
    const keyDataStartTime = shiftData.filter((val) => val.start_time === 'Invalid date').map(((x) => x.id));
    const keyNullStartTime = shiftData.filter((val) => val.start_time === 'null').map(((x) => x.id));
    const keyNullEndTime = shiftData.filter((val) => val.end_time === 'null').map(((x) => x.id));
    const keyDataEndTime = shiftData.filter((val) => val.end_time === 'Invalid date').map(((x) => x.id));
    const keyDataLabel = shiftData.filter((val) => !val.label.match(regExp)).map(((x) => x.id));
    const keyDuplicateLabel = shiftData.filter((val, index, self) => index !== self.findIndex((x) => x.label.toLowerCase() === val.label.toLowerCase())).map((val) => val.id);
    const keyDuplicatTime = shiftData.filter((val) => val.start_time === val.end_time).map((x)=>x.id);
    const KeyEndMoreThanStart = shiftData.filter((val) => moment(val.start_time, 'hh:mm') > moment(val.end_time, 'hh:mm')).map((x)=>x.id);
    const error = {};

    if (keyNullStartTime.length > 0 || keyNullEndTime.length > 0 || keyDataStartTime.length > 0 || keyDataEndTime.length > 0 || keyDataLabel.length > 0 || keyDuplicateLabel.length > 0 || keyDuplicatTime.length > 0 || KeyEndMoreThanStart.length > 0) {
      if (keyDuplicateLabel.length > 0) error.label = {key: keyDuplicateLabel, value: 'Shift Name have same name'};
      if (keyDuplicatTime.length > 0) {
        error.start_time = {key: keyDuplicatTime, value: 'Start shift have same time with end shift'};
        error.end_time = {key: keyDuplicatTime, value: 'End shift have same time with start shift'};
      }
      if (keyDataStartTime.length > 0) error.start_time = {key: keyDataStartTime, value: 'Start shift is not valid'};
      if (keyDataEndTime.length > 0) error.end_time = {key: keyDataStartTime, value: 'End shift is not valid'};
      if (KeyEndMoreThanStart.length > 0) error.end_time = {key: KeyEndMoreThanStart, value: 'End shift cannot be faster than start shift'};
      this.setState({
        errorMessage: error,
        errorMessageRequired: {
          start_time: {
            key: keyNullStartTime,
            value: 'Start shift is required',
          },
          end_time: {
            key: keyNullEndTime,
            value: 'End shift is required',
          },
          label: {
            key: keyDataLabel,
            value: 'Shift Name cannot be empty',
          },
        },
      });
    } else {
      const {formDataUpdate, errorMessage} = this.state;
      const converted = [];
      let newVal;
      const newFormDataUpdate = [
        formDataUpdate.check_in,
        formDataUpdate.shift_setting,
      ];

      const newDataUpdate = newFormDataUpdate.filter((values) => {
        return values !== null;
      });
      newDataUpdate.map((val, key) => {
        if (val.key === 'check_in') {
          newVal = JSON.stringify(this.state.formDataUpdate.shifts);
        }

        if (val.key === 'shift_setting') {
          newVal = JSON.stringify(this.state.formDataUpdate.shift_setting.value);
        }

        converted.push({
          key: val.key,
          label: val.label,
          value: newVal,
          group: val.group,
        });
      });

      const params = {data: converted};
      if (errorMessage && (errorMessage.min || errorMessage.max)) {
        toast('error', errorMessage.min ? errorMessage.min : errorMessage.max);
      } else {
        this.props.updateGeneralSetting(params);
        this.setState({
          errorMessage: null,
        });
      }
      }
  }

  handleInputChangeSetting = (type, params) => {
    let name;
    let value;
    if (type === 'text') {
      name = params.target.name;
      value = params.target.value;
    } else if (type === 'multiple' || type === 'datetime') {
      name = params.name;
      value = params.value;
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [name]: {
          ...this.state.formData[name],
          value,
        },
      },
      formDataUpdate: {
        ...this.state.formDataUpdate,
        [name]: {
          ...this.state.formDataUpdate[name],
          value,
        },
      },
    });
  };

  renderCheckInRow = (data) => {
    const {classes} = this.props;
    const {errorMessage, errorMessageRequired} = this.state;
    let shift_num;
    return data.map((value, index) => {
      return (
        <MuiThemeProvider theme={this.getMuiTheme()}>
        <Grid container spacing={2} key={index.toString()}>
          <Grid item xs={12} className={classes.my10}>
            <MKeyboardTimePicker
              inputVariant="outlined"
              className={classes.shiftFormControl}
              label={`Start Shift`}
              name={`time_${value.id}`}
              value={value.start_time ? moment(value.start_time, 'hh:mm') : moment('08:00', 'hh:mm')}
              onTimeChange={(event) => this.handleShiftChange(data, event, value, 'start_time')}
              ampm={false}
              error={errorMessageRequired && errorMessageRequired.start_time && errorMessageRequired.start_time.key.find((x)=>x===value.id) ? errorMessageRequired.start_time.value : errorMessage && errorMessage.start_time && errorMessage.start_time.key.find((x)=>x===value.id) ? errorMessage.start_time.value : undefined}
            />


            <MKeyboardTimePicker
              inputVariant="outlined"
              className={classes.shiftFormControl}
              label={`End Shift`}
              name={`time_${value.id}`}
              value={value.end_time ? moment(value.end_time, 'hh:mm') : moment('17:00', 'hh:mm')}
              onTimeChange={(event) => this.handleShiftChange(data, event, value, 'end_time')}
              ampm={false}
              error={errorMessageRequired && errorMessageRequired.end_time && errorMessageRequired.end_time.key.find((x)=>x===value.id) ? errorMessageRequired.end_time.value : errorMessage && errorMessage.end_time && errorMessage.end_time.key.find((x)=>x===value.id) ? errorMessage.end_time.value : undefined}
            />


          {
            data.length > 1 ?
            (
              <MInput
                  key={value.id}
                    inputVariant="outlined"
                    className={classes.nameFormControl}
                    name={`shift_name_${value.id}`}
                    label={`Shift Name`}
                    placeholder="Enter name"
                    defaultValue={value.label}
                    maxLength={20}
                    onBlur={(event) => {
                      this.handleShiftChange(data, event.target, value, 'text');
                    }}
                    fullWidth
                    error={errorMessage && errorMessage.label && errorMessage.label.key.find((x)=>x===value.id) ? errorMessage.label.value : errorMessageRequired && errorMessageRequired.label && errorMessageRequired.label.key.find((x)=>x===value.id) ? errorMessageRequired.label.value : undefined}
                  />
            ) : null
          }
          {
            (data.length > 1) && (index > 0) ?(
                <MButton
                  className={classes.btnDelete}
                  label="Delete"
                  color="secondary"
                  size='small'
                  icon={<Delete />}
                  onClick={() => showPopup(
                    'confirm',
                    'Are you sure you want to delete this shift ?',
                    'Yes',
                    () => {
                      this.handleDeleteShift(data, value);
                    },
                  )}
                />
              ) : <div></div>
          }
        </Grid>
        </Grid>
        </MuiThemeProvider>
      );
    });
  }

  renderForm = (settingArray) => {
    const {classes} = this.props;
    const {shift_setting} = this.state.formDataUpdate;
    const {errorMessage} = this.state;
    const result = settingArray.map((setting) => {
      if (setting) {
        const {id, key} = setting;
        if (key === 'check_in') {
          const {shifts} = this.state.formDataUpdate;
          return (
            <MuiThemeProvider theme={this.withMuiTheme()}>
            <div key={id} className={classes.my10}>
              <h5 className={classes.subTitle}>Shift Settings</h5>
              <Grid container spacing={2} className={classes.my10}>
                <Grid item xs={4}>
                  <MInput
                    inputVariant="outlined"
                    classNameFC={classes.formControl}
                    label={'Min. check-in period (Minutes)'}
                    name={'min'}
                    defaultValue={shift_setting && shift_setting.value.min}
                    onBlur={this.handleInputChange}
                    fullWidth
                    placeholder="Minutes before shift start"
                    error={errorMessage && errorMessage.min ? errorMessage.min : undefined}
                    type='number'
                  />
                </Grid>
                <Grid item xs={4}>
                  <MInput
                    inputVariant="outlined"
                    classNameFC={classes.formControl}
                    label={'Max. check-in period (Minutes)'}
                    name={'tolerance'}
                    defaultValue={shift_setting && shift_setting.value.tolerance}
                    onBlur={this.handleInputChange}
                    fullWidth
                    placeholder="Minutes after shift start"
                    error={errorMessage && errorMessage.tolerance ? errorMessage.tolerance : undefined}
                    type='number'
                  />
                </Grid>
                <Grid item xs={4}>
                  <MInput
                    inputVariant="outlined"
                    classNameFC={classes.formControl}
                    label={'Max. check-out period (Minutes)'}
                    name={'max'}
                    defaultValue={shift_setting && shift_setting.value.max}
                    onBlur={this.handleInputChange}
                    fullWidth
                    placeholder="Minutes after shift end"
                    error={errorMessage && errorMessage.max ? errorMessage.max : undefined}
                    type='number'
                  />
                </Grid>
              </Grid>
              <h5 className={classes.subTitle}>Shift</h5>
              <Grid item xs={12}>
                <MButton
                  className={classes.btnAdd}
                  label="Add New"
                  color="primary"
                  size='small'
                  icon={<Add />}
                  onClick={() => this.handleAddShift(shifts)}
                />
              </Grid>
              {shifts && shifts.length > 0 ? this.renderCheckInRow(shifts) : null}
            </div>
            </MuiThemeProvider>
          );
        }
      }
    });
    return result;
  }

  handleAddShift = (shifts) => {
    if (shifts.length < 24) {
      if (shifts && shifts.length > 0) {
        const lastValue = _.last(shifts);
        return this.setState({
          formDataUpdate: {
            ...this.state.formDataUpdate,
            shifts: shifts.concat([{id: lastValue.id + 1, label: `Shift ${lastValue.id + 1}`, start_time: '08:00', end_time: '17:00'}]),
          },
        });
      } else {
        return this.setState({
          formDataUpdate: {
            ...this.state.formDataUpdate,
            shifts: [{id: 1, label: `Shift 1`, start_time: '08:00', end_time: '17:00'}],
          },
        });
      }
    } else {
      return toast('error', 'Total shifts exceeded');
    }
  };

  handleInputChange = (event) => {
    const {name, value} = event.target;
    const re = /^[0-9\b]+$/;

    if (re.test(value)) {
      this.setState({
        formDataUpdate: {
          ...this.state.formDataUpdate,
          shift_setting: {
            ...this.state.formDataUpdate.shift_setting,
            value: {
              ...this.state.formDataUpdate.shift_setting.value,
              [name]: value,
            },
          },
        },
        errorMessage: {
          ...this.state.errorMessage,
          [name]: null,
        },
      });
    } else if (value==='') {
      this.setState({
        errorMessage: {
          ...this.state.errorMessage,
          [name]: name === 'min' ? 'Min. check-in period can not be empty' : name === 'tolerance' ? 'Max. check-in period can not be empty' : 'Max. check-out period can not be empty',
        },
      });
    } else {
      this.setState({
        errorMessage: {
          ...this.state.errorMessage,
          [name]: 'Period time is not valid',
        },
      });
    }
  }

  handleShiftChange = (shifts, event, value, type) => {
    const index = _.findIndex(shifts, {id: value.id});
    const {errorMessage} = this.state;
    const errorStartTime = shifts.filter((val) => val.start_time === 'Invalid date').map(((x) => x.id));
    const errorEndTime = shifts.filter((val) => val.end_time === 'Invalid date').map(((x) => x.id));

    if (type === 'start_time') {
      if (moment(event.value).format('HH:mm') !== 'Invalid date') {
        shifts.splice(index, 1, {...value, start_time: event.value ? moment(event.value).format('HH:mm') : 'null'});
        this.setState({
          errorMessage: {
            ...errorMessage,
            start_time: {
              key: [...errorStartTime.filter((val)=> val !== value.id)],
              value: 'Start shift is not valid',
            },
          },
        });
      } else {
        this.setState({
          errorMessage: {
            ...errorMessage,
            start_time: {
              key: [...errorStartTime, value.id],
              value: 'Start shift is not valid',
            },
          },
        });
        shifts.splice(index, 1, {...value, start_time: event.value ? moment(event.value).format('HH:mm') : 'null'});
      }
    } else if (type === 'end_time') {
      if (moment(event.value).format('HH:mm') !== 'Invalid date') {
        shifts.splice(index, 1, {...value, end_time: event.value ? moment(event.value).format('HH:mm') : 'null'});
        this.setState({
          errorMessage: {
            ...errorMessage,
            end_time: {
              key: [...errorEndTime.filter((val)=> val !== value.id)],
              value: 'End shift is not valid',
            },
          },
        });
      } else {
        this.setState({
          errorMessage: {
            ...errorMessage,
            end_time: {
              key: [...errorEndTime, value.id],
              value: 'End shift is not valid',
            },
          },
        });
        shifts.splice(index, 1, {...value, end_time: event.value ? moment(event.value).format('HH:mm') : 'null'});
      }
    } else if (type === 'text' && event.value !== '') {
      shifts.splice(index, 1, {...value, label: event.value});
    } else {
      shifts.splice(index, 1, {...value, label: ''});
    }

    return this.setState({
      formDataUpdate: {
        ...this.state.formDataUpdate,
        shifts: shifts,
      },
    });
  };

  handleDeleteShift = (data, value) => {
    _.remove(data, {id: value.id});
    this.setState({
      formDataUpdate: {
        ...this.state.formDataUpdate,
        shifts: data,
        },
    });
  };

  render() {
    const {classes, data, isLoading} = this.props;
    const {settings} = this.state;
    return (
      <div className={classes.root}>
        <Paper variant='outlined' className={classes.paper}>
          <form>
            {
              settings.length > 0 ?
              this.renderForm(settings) : ''
            }
            <div className={classes.btnContainer}>
              <MButton
                className={classes.defaultBtn}
                color="primary"
                label="Update Setting"
                icon={<Send/>}
                onClick={this.handleSubmit}
                loading={isLoading && data.length > 0 ? true : false}
              />
            </div>
          </form>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = ({auth, generalSetting, jobTitle}) => {
  return ({
    isSuperAdmin: checkSuperadmin(auth),
    data: generalSetting.data,
    isLoading: generalSetting.isLoading,
    isSuccess: generalSetting.isSuccess,
    isError: generalSetting.isError,
    message: generalSetting.message,
    trigger: generalSetting.trigger,
  });
};

const mapDispatchToProps = (dispatch) => ({
  getGeneralSetting: (key) => dispatch(getGeneralSetting(key)),
  updateGeneralSetting: (params) => dispatch(updateGeneralSetting(params)),
  checkStatus: () => dispatch(checkStatus()),
  checkStatusUser: () => dispatch(checkStatusUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ShiftSetting));
