/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import {
  Button,
  CircularProgress,
  TextField,
  FormControl,
  FormHelperText,
  FormControlLabel,
  FormLabel,
  FormGroup,
  InputLabel,
  Select,
  MenuItem,
  DialogTitle,
  IconButton,
  Checkbox,
  MuiThemeProvider,
  createTheme as createThemes,
} from '@material-ui/core';
import {
  Alarm,
  Close,
  Delete,
} from '@material-ui/icons';
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
  DatePicker,
} from '@material-ui/pickers';
import {useStyles} from './styles';
import DateFnsUtils from '@date-io/date-fns';
import {validateRegex} from '../../helpers';
import _ from 'lodash';
import {ThemeProvider, createTheme} from '@mui/material';
import InputMask from 'react-input-mask';


export const ModalTitle = ({title, onClose, noClose, ...props}) => {
  const classes = useStyles();
  const dialogTitleTheme = () => createThemes({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiTypography: {
        h6: {
          fontWeight: 600,
          color: '#656464',
        },
      },
    },
  });

  return (
    <MuiThemeProvider theme={dialogTitleTheme()}>
      <DialogTitle
        {...props}
        className={classes.modalTitle}
      >
        {title}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          {noClose ? (
            null
          ) : (
            <Close />
          )}
        </IconButton>
      </DialogTitle>
    </MuiThemeProvider>
  );
};

export const ModalTitleAgreement = ({title, onClose, noClose, ...props}) => {
  const classes = useStyles();
  const dialogTitleTheme = () => createThemes({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiTypography: {
        h6: {
          fontWeight: 700,
          color: '#444B55',
          fontSize: 24,
        },
      },
    },
  });

  return (
    <MuiThemeProvider theme={dialogTitleTheme()}>
      <DialogTitle
        {...props}
        className={classes.modalTitle}
      >
        {title}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          {noClose ? (
            null
          ) : (
            <Close />
          )}
        </IconButton>
      </DialogTitle>
    </MuiThemeProvider>
  );
};

export const MButton = ({loading, label, icon, loaderSize, ...props}) => {
  return (
    <Button
      variant="contained"
      startIcon={
        icon && loading ?
          <CircularProgress color="inherit" size={loaderSize ? loaderSize : 15} /> :
          icon ?
            icon :
            undefined
      }
      disabled={loading ? true : false}
      {...props}
    >
      {label}
    </Button>
  );
};


export const MSelectSearch = ({fullWidth, label, getOptionLabel, shrink, classNameFC, error, keyPair, options, variant, loading, ...props}) => {
  const classes = useStyles();

  const selectSearchTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          listbox: {
            maxHeight: 200,
          },
        },
      },
    },
  });


  return (
    <ThemeProvider theme={selectSearchTheme()}>
      <FormControl
        variant="outlined"
        fullWidth={fullWidth === false ? false : true}
        className={classNameFC ? classNameFC : classes.formControlAutoComplete}
      >
        <Autocomplete
          id="select-search"
          options={options}
          renderInput={(params) =>
            <TextField
              {...params}
              label={label ? label : null}
              error={error ? true : false}
              helperText={error ? error : null}
              variant={variant ? variant : 'standard'}
            />
          }
          {...props}
        />
      </FormControl>
    </ThemeProvider>
  );
};

export const MInput = ({fullWidth, helper, classNameFC, error, maxRows, type, shrink, maxLength, onBlur, validate, inputProps, onButtonCustom, onButtonCustomIcon, onButtonCustomColor, isLoading, variant, ...props}) => {
  const classes = useStyles();
  const handleBlur = (event) => { // onChange cause performance hit, so alternative is onBlur
    // dont validate onBlur if component has validate={false} prop
    if (validate !== false) event.target.value = validateRegex(event.target.value, type);
    return onBlur(event);
  };

  return (
    <FormControl
      variant={variant || 'outlined'}
      fullWidth={fullWidth === false ? false : true}
      className={classNameFC ? classNameFC : classes.formControl}
    >
      <TextField
        InputLabelProps={{shrink: true}}
        inputProps={inputProps ? inputProps : type === 'number' ? {min: 0} : {}}
        onWheel={props?.onWheel ? props.onWheel : (e) => e.target.blur()}
        fullWidth={fullWidth === false ? false : true}
        variant={variant || 'outlined'}
        error={error ? true : false}
        helperText={error ? error : helper ? helper : undefined}
        type={type ? type : 'text'}
        onBlur={validate !== false && onBlur ? onBlur : onBlur} // dont validate onBlur if component has validate={false} prop
        maxRows={maxRows ? maxRows : null}
        onKeyDown={(event) => {
          if (type === 'number') {
            if (event.key === '.' || event.key === '-' || event.key === 'e' || event.key === ',') {
              event.preventDefault();
            }
          }
        }}
        {...props}
      />
      {
        onButtonCustom ?
          <div className={classes.wrapperOnButtonCustom}>
            <IconButton disabled={isLoading ? true : false} onClick={() => onButtonCustom()}>
              {isLoading ?
                <CircularProgress color="inherit" size={15} style={{marginTop: 5}} /> :
                onButtonCustomIcon ?
                  onButtonCustomIcon :
                  <Delete color={onButtonCustomColor ? onButtonCustomColor : 'error'} />
              }
            </IconButton>
          </div> :
          null
      }
    </FormControl>
  );
};

export const MInputMask = ({fullWidth, helper, classNameFC, error, maxRows, type, shrink, maxLength, onBlur, validate, inputProps, onButtonCustom, onButtonCustomIcon, onButtonCustomColor, isLoading, ...props}) => {
  const classes = useStyles();

  return (
    <InputMask
      mask="999999999999999999"
      onBlur={validate !== false && onBlur ? onBlur : onBlur} // dont validate onBlur if component has validate={false} prop
      maskChar={null}
      className={classNameFC ? classNameFC : classes.formControl}
      {...props}
    >
      {() => <TextField
        InputLabelProps={{shrink: true}}
        fullWidth={fullWidth === false ? false : true}
        variant='outlined'
        className={classNameFC ? classNameFC : classes.formControl}
        margin="normal"
        error={error ? true : false}
        helperText={error ? error : helper ? helper : undefined}
        {...props}
      />}
    </InputMask>
  );
};

export const MInputMaskNPWP = ({fullWidth, helper, classNameFC, error, maxRows, type, shrink, maxLength, onBlur, validate, inputProps, onButtonCustom, onButtonCustomIcon, onButtonCustomColor, isLoading, ...props}) => {
  const classes = useStyles();

  return (
    <InputMask
      mask="99.999.999.9.-999.999"
      onBlur={validate !== false && onBlur ? onBlur : onBlur} // dont validate onBlur if component has validate={false} prop
      maskChar={null}
      className={classNameFC ? classNameFC : classes.formControl}
      {...props}
    >
      {() => <TextField
        InputLabelProps={{shrink: true}}
        fullWidth={fullWidth === false ? false : true}
        variant='outlined'
        className={classNameFC ? classNameFC : classes.formControl}
        margin="normal"
        error={error ? true : false}
        helperText={error ? error : helper ? helper : undefined}
        {...props}
      />}
    </InputMask>
  );
};

export const MSelect = ({fullWidth, shrink, classNameFC, error, keyPair, options, variant, loading, scrollable, ...props}) => {
  const classes = useStyles();
  const renderOptions = (options) => {
    return options.map((value) => {
      // destructure the data row based on keyPair array value
      // eg: keyPair = ['id', 'name'] will take (id => name) from the data row
      const {[keyPair[0]]: id, [keyPair[1]]: text} = value;
      return <MenuItem key={id} value={id}>{text}</MenuItem>;
    });
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <FormControl
      variant={variant ? variant : 'outlined'}
      fullWidth={fullWidth === false ? false : true}
      className={classNameFC ? classNameFC : classes.formControl}
    >
      <InputLabel
        shrink={shrink ? shrink : undefined}
      >{props.label}</InputLabel>
      <Select
        MenuProps={MenuProps}
        error={error ? true : false}
        variant={variant ? variant : 'outlined'}
        IconComponent={loading ? () => <CircularProgress color="inherit" size={15} style={{marginRight: 15}} /> : undefined}
        {...props}
      >
        {renderOptions(options)}
      </Select>
      {error ? (<FormHelperText className={classes.hasError}>{error}</FormHelperText>) : ''}
    </FormControl>
  );
};

export const MSelectRole = ({fullWidth, shrink, classNameFC, error, keyPair, options, variant, loading, scrollable, isError, ...props}) => {
  const classes = useStyles();
  const renderOptions = (options) => {
    return options.map((value) => {
      // destructure the data row based on keyPair array value
      // eg: keyPair = ['id', 'name'] will take (id => name) from the data row
      const {[keyPair[0]]: id, [keyPair[1]]: text, [keyPair[2]]: isDisabled} = value;
      return <MenuItem disabled={isDisabled} key={id} value={id}>{text}</MenuItem>;
    });
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <FormControl
      variant={variant ? variant : 'outlined'}
      fullWidth={fullWidth === false ? false : true}
      className={classNameFC ? classNameFC : classes.formControl}
    >
      <InputLabel
        shrink={shrink ? shrink : undefined}
      >{props.label}</InputLabel>
      <Select
        MenuProps={MenuProps}
        error={error && isError ? true : false}
        variant={variant ? variant : 'outlined'}
        IconComponent={loading ? () => <CircularProgress color="inherit" size={15} style={{marginRight: 15}} /> : undefined}
        {...props}
      >
        {renderOptions(options)}
      </Select>
      {error ? (<FormHelperText className={isError === true ? classes.hasError : undefined}>{error}</FormHelperText>) : ''}
    </FormControl>
  );
};


export const MKeyBoardDatepicker = ({name, onDateChange, error, variant, fullWidth, ...props}) => {
  const handleChange = (value) => { // MUI datepicker component only returns value as onChange event from the package
    onDateChange({name, value});
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        autoOk
        disableToolbar
        fullWidth={fullWidth ? fullWidth : false}
        inputVariant={variant ? variant : 'standard'}
        format="dd-MM-yyyy"
        margin="normal"
        KeyboardButtonProps={{'aria-label': 'Change Date'}}
        onChange={handleChange}
        InputLabelProps={{shrink: true}}
        error={error ? error : undefined}
        helperText={error ? error : undefined}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

export const MDatepicker = ({name, onDateChange, error, format, ...props}) => {
  const handleChange = (value) => { // MUI datepicker component only returns value as onChange event from the package
    onDateChange({name, value});
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        format={format ? format : 'dd-MM-yyyy'}
        onChange={handleChange}
        InputLabelProps={{shrink: true}}
        error={error ? error : undefined}
        helperText={error ? error : undefined}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

// for user management not globally
export const SelectSingleSearch = ({
  data, keyPair, loading, className, name, label, placeholder, value, error,
  ...props
}) => {
  const renderOptions = (options) => options.map((value) => ({id: value[keyPair[0]], label: value[keyPair[1]]}));
  const handleChange = (event, newValue) => {
    // console.log(newValue)
    // const ids = newValue[keyPair[0]];
    // props.onChange(ids);
  };

  const mapValues = (array, options) => options.filter((item) => array.includes(item.id));

  const options = Array.isArray(data) && data.length ? renderOptions(data) : [];
  const mappedValues = Array.isArray(value) && value.length ? mapValues(value, options) : [];

  return (
    <Autocomplete
      {...props}
      id={name}
      className={className}
      options={options}
      getOptionLabel={(option) => option.label}
      loading={loading}
      onChange={handleChange}
      value={mappedValues}
      filterSelectedOptions
      filterOptions={(options) => {
        const values = value && value.length ? value.map((val) => val[keyPair[0]]) : [];
        return options.filter((option) => !values.includes(option.id));
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          variant="outlined"
          label={label}
          placeholder={placeholder}
          InputLabelProps={{shrink: true}}
          error={error ? true : false}
          helperText={error ? error : undefined}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export const SelectMultiple = ({
  data, keyPair, loading, className, name, label, placeholder, value, error,
  ...props
}) => {
  const renderOptions = (options) => options.map((value) => ({id: value[keyPair[0]], label: value[keyPair[1]]}));

  const handleChange = (event, newValue) => {
    const ids = newValue.map((value) => value.id);
    props.onChange(ids);
  };

  const mapValues = (array, options) => {
    if (_.isObject(array[0])) {
      return array;
    } else {
      return options.filter((item) => array.includes(item.id));
    }
  };

  const options = Array.isArray(data) && data.length ? renderOptions(data) : [];
  const mappedValues = Array.isArray(value) && value.length ? mapValues(value, options) : [];

  return (
    <Autocomplete
      {...props}
      multiple
      id={name}
      className={className}
      options={options}
      getOptionLabel={(option) => option.label}
      loading={loading}
      onChange={handleChange}
      defaultValue={mappedValues}
      filterSelectedOptions
      filterOptions={(options) => {
        const values = value && value.length ? value.map((val) => val[keyPair[0]]) : [];
        return options.filter((option) => !values.includes(option.id));
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          variant="outlined"
          label={label}
          placeholder={placeholder}
          InputLabelProps={{shrink: true}}
          error={error ? true : false}
          helperText={error ? error : undefined}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export const MKeyboardTimePicker = ({name, onTimeChange, error, ...props}) => {
  const handleChange = (value) => {
    onTimeChange({name, value});
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        variant="inline"
        margin="normal"
        onChange={handleChange}
        InputLabelProps={{shrink: true}}
        error={error ? error : undefined}
        helperText={error ? error : undefined}
        keyboardIcon={<Alarm />}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

export const MCheckboxItem = ({classNameFC, label, color, ...props}) => {
  return (
    <FormControlLabel
      className={classNameFC ? classNameFC : undefined}
      control={
        <Checkbox
          color={color ? color : 'primary'}
          {...props}
        />
      }
      label={label ? label : undefined}
    />
  );
};

export const MCheckboxGroup = ({classNameFC, classNameGroup, label, error, row, ...props}) => {
  const classes = useStyles();
  return (
    <FormControl
      className={classNameFC ? classNameFC : classes.checkboxGroup}
    >
      {label ? <FormLabel component="legend" className={classes.checkboxLabel}>{label}</FormLabel> : ''}
      <FormGroup
        row={row ? row : undefined}
        className={classNameGroup ? classNameGroup : undefined}
      >
        {props.children}
      </FormGroup>
      {error ? (<FormHelperText className={classes.hasError}>{error}</FormHelperText>) : ''}
    </FormControl>
  );
};


export const MAutoComplete = ({
  label,
  value,
  inputValue,
  onChange,
  onInputChange,
  options,
  errorMessage,
  disabled,
  className,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Autocomplete
      id="controllable-states-demo"
      disablePortal
      disableClearable
      className={className ? className : classes.formControl}
      variant='outlined'
      value={value ? value : ''}
      onChange={onChange ? onChange : undefined}
      inputValue={inputValue ? inputValue : undefined}
      onInputChange={onInputChange ? onInputChange : undefined}
      options={options ? options : []}
      disabled={disabled ? true : false}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='outlined'
          label={label ? label : undefined}
          error={errorMessage ? true : false}
          helperText={errorMessage ? errorMessage : null}
        />
      )}
      {...props}
    />
  );
};

